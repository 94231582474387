import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const defaultMapping = {
  key: "key",
  value: "value",
};

const styles = {
  textTypo: {
    fontFamily: 'Noto Sans',
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 24,
    letterSpacing: 0.15000000596046448,
    textAlign: "left",
  },
};

export const renderSelect = (
  elements = [],
  mapping = defaultMapping,
  handleClose = () => {}
) =>
  elements.map((element) => (
    <MenuItem
      key={element[mapping.key]}
      value={element[mapping.key]}
      onClick={() => {
        if (typeof element.onClick === "function") {
          element.onClick();
        }
        handleClose();
      }}
    >
      {element.icon != null && <ListItemIcon>{element.icon}</ListItemIcon>}
        <p className={styles.textTypo}>{element[mapping.value]}</p>
    </MenuItem>
  ));
