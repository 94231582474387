import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import archiLogo from '../../images/archiscan_logo.png';
import {connect} from "react-redux";
import { requestLogin } from './actions';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginFailed } from './selector';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {ReactComponent as Visibility} from '../../images/icons/Visibility.svg';
import {ReactComponent as VisibilityOff} from '../../images/icons/VisibilityOff.svg';
import Typography from "@material-ui/core/Typography";
import Warning from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";



const styles = () => ({
    paperContainer: {
        display: "flex",
        flexDirection: "column",
        width: 456,
        height: 466,
        top: 214,
        left: 492,
        justifyContent: "center",
        borderRadius: 20,
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.13)',
    },
    logo: {
        marginBottom: 47,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    root: {
        //display: 'flex',
        //flexWrap: 'wrap',
    },
    margin: {
        margin: 10,
    },
    textField: {
        width: 392,
        height: 56,
        borderRadius: '8px !important',
    },
    buttonFormStyle: {
        color: 'white !important',
        marginTop: 30,
        width: 392,
        height: 56,
        background: '#10277C',
        borderRadius: '8px !important',
    },
    disabledButton: {
        background: '#DCE0EB !important',
        color: '#B3B9C8 !important',
        borderRadius: '8px !important',
    },
});


class Login extends Component {

    state = {
        email: '',
        password: '',
        visiblePassword: false,
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.email !== '' && this.state.password !== '') {
            this.props.requestLogin(this.state.email, this.state.password);
        }
    };

    handleClickShowPassword() {
        this.setState((state) => ({ visiblePassword: !state.visiblePassword }));
    }


    render() {
        const { classes, loginFailed } = this.props;
        const { password, email } = this.state;
        return (
            <div style={{ height: "100%", backgroundColor: "#E5E5E5", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Paper
                className={classes.paperContainer}
                elevation={3}
            >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img className={classes.logo} src={archiLogo} alt="Logo ArchiScan" />
                </div>
                    <form
                        className={classes.formContainer}
                        onSubmit={this.handleSubmit}
                        noValidate
                    >
                        <div>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <TextField
                                        label="Username"
                                        variant="outlined"
                                        value={email}
                                        onChange={event =>
                                            this.setState({ email: event.target.value })
                                        }
                                        name="email"
                                        error={loginFailed}
                                        style={{borderRadius: 8}}
                                    />
                                </FormControl>
                        </div>
                    <div>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                    <TextField
                                        variant="outlined"
                                        label="Mot de passe"
                                        value={password}
                                        type={this.state.visiblePassword ? 'text' : 'password'}
                                        className={classes.textField}
                                        onChange={event =>
                                            this.setState({ password: event.target.value })
                                        }
                                        name="password"
                                        error={loginFailed}
                                        InputProps={{
                                            endAdornment :
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={()=>this.handleClickShowPassword()}
                                                >
                                                    {this.state.visiblePassword ? (
                                                        <Visibility/>
                                                    ) : (
                                                        <VisibilityOff/>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </FormControl>
                    </div>
                        <div>
                            {loginFailed && (
                                <Fragment>
                                    <Typography className={classes.error}>
                                        Email et/ou mot de passe érronés
                                    </Typography>
                                    <Warning
                                        className={classes.error} fontSize="small"
                                    />
                                </Fragment>
                            )}
                        </div>
                        <div>
                            <Button
                                data-testid="login-button"
                                classes={{ disabled: classes.disabledButton }}
                                className={classes.buttonFormStyle}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={password === '' || email === ''}
                            >
                                Se connecter
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        );

    }
}

const mapDispatchToProps = {
    requestLogin,
};

const mapStateToProps = createStructuredSelector({
    loginFailed: makeSelectLoginFailed(),
});


Login.propTypes = {
    requestLogin: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Login));



