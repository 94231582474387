export const ADD_TOAST = 'ADD_TOAST';
export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const DONE_TEXT = 'DONE';
export const ERROR_TEXT = 'ERROR';
export const WARNING_TEXT = 'WARNING';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_ERROR = 'ERROR';
export const STATUS_WARNING = 'WARNING';
export const toastType = {
  SUCCESS: 'SUCCESS_TOAST_TYPE',
  ERROR: 'ERROR_TOAST_TYPE',
  WARNING: 'WARNING_TOAST_TYPE',
};
export const TIMEOUT_TOAST = 4000; // ms
