import React from 'react';
import Card from '@material-ui/core/Card/Card';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { removeToast } from './actions';

export const supportStyle = {
  width: '90px',
  height: '16px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
};

export const supportTextStyle = {
  color: 'white',
  fontSize: '10px',
  fontWeight: 'bold',
  margin: 'auto',
  textAlign: 'center',
};

const colors ={
  SUPPORT_RED: '#f5523c',
  SUPPORT_ORANGE: '#f4b63e',
  SUPPORT_GREEN: '#32c284',
};

const styles = () => ({
  card: {
    marginTop: '16px',
    boxShadow: '0 3px 25px 0 rgba(114, 131, 151, 0.4)',
  },
  toastCard: {
    width: '400px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 8,
  },
  toastCardContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '12px',
    marginBottom: '12px',
    marginLeft: 20,
  },
  textCardContent: {
    color: 'white',
  },
  toastRectangle: {
    ...supportStyle,
    margin: '24px',
  },
  toastTitle: {
    fontSize: '14px',
    fontWeight: '400',
  },
  toastText: {
    fontSize: '12px',
  },
  linkText: {
    color: '#F5523C',
    fontFamily: 'Noto Sans',
    fontSize: '12px',
    marginTop: '2px',
  },
  rectangleText: supportTextStyle,
  sideBar: {
    width: '8px',
    position: 'absolute',
    height: '100%',
    marginRight: 12,
  },
});

class ToastElement extends React.PureComponent{
  render() {
    const { classes, toast } = this.props;
    const style = {
      color: {
        backgroundColor: toast.style.backgroundColor,
      },
    };
    return (
      <Card className={classes.card}>
        <div className={classes.toastCard}>
          <div className={classes.sideBar} style={style.color} />
          <div className={classes.toastCardContent}>
            {/*
             <Typography classes={{ body2: classes.toastTitle }}>
                {toast.title}
             </Typography>
             */}
            <Typography className={ classes.toastText }>
              {toast.message}
            </Typography>
            {toast.errors &&
              toast.errors.data && (
                <a
                  href={`data:text/plain,${toast.errors.data.join('\r\n')}`}
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: 'red',
                  }}
                >
                  <Typography classes={{ body2: classes.linkText }}>
                    Cliquez ici pour télécharger les logs
                  </Typography>
                </a>
              )}
          </div>
          <IconButton
            aria-label="Close"
            onClick={() => this.props.removeToast(toast.id)}
          >
            <Clear />
          </IconButton>
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  removeToast,
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(ToastElement));
