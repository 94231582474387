import { clear, set } from '../appStorage';
import { ACCESS_TOKEN_STORAGE, USER_STORAGE } from '../../common/constant';
import { authUrl } from '../../utils/authConfig';

const storeLoginData = loginResponse => {
  set(USER_STORAGE, loginResponse);
};

const clearLoginData = () => {
  clear(ACCESS_TOKEN_STORAGE);
  clear(USER_STORAGE);
  window.location.href = authUrl
};

export default {
  storeLoginData,
  clearLoginData,
};
