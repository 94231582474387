import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";
import { apiEndPoints } from "../../config/end-points";
import { addToast } from "../Toast/actions";
import { STATUS_ERROR, STATUS_SUCCESS } from "../Toast/constants";
import { DELETE_DOCUMENT, REQUEST_DOCUMENTS } from "./constants";
import {
  nextDocumentsPage,
  setDocuments,
  setDocumentsCount,
  requestDocuments as requestDocumentsActions,
} from "./action";
import { defaultFilterCriteria } from "./FilterConfig";

export function* requestDocuments(action) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(action.filterCriteria),
  };
  try {
    const documents = yield call(
      request,
      apiEndPoints.documents(action.searchCriteria),
      options
    );
    if (documents && documents.length !== 0) {
      yield put(setDocuments(documents.content));
      yield put(setDocumentsCount(documents.totalElements));
      yield put(nextDocumentsPage());
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestDocumentsSaga() {
  yield takeLatest(REQUEST_DOCUMENTS, requestDocuments);
}

export function* requestDeleteDocument(action) {
  const options = {
    method: "DELETE",
  };

  try {
    yield call(request, apiEndPoints.deleteDocument(action.id), options);
    action.refresh();
    yield put(
      addToast({
        status: STATUS_SUCCESS,
        title: "SUCCESS",
        message: "Votre opération a été exécutée avec succès",
      })
    );
  } catch (e) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(e.message);
  }
}

export function* requestDeleteDocumentSaga() {
  yield takeLatest(DELETE_DOCUMENT, requestDeleteDocument);
}

export default [requestDocumentsSaga, requestDeleteDocumentSaga];
