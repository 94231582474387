import React, { Component } from "react";
import AddUser from "../../components/AddUser";
import Sidebar from "../../components/Sidebar";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import { requestAddUser } from "./actions";
import { requestFieldTypes } from "../AddFieldPage/action";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectServices } from "../PopupContainer/selector";
import { requestServices, requestUsers } from "../PopupContainer/action";
import { makeSelectFieldTypes } from "../AddFieldPage/selector";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
};

class AddUserPage extends Component {
  componentWillMount() {
    if (!this.props.services || this.props.services?.length === 0) {
      this.props.requestServices();
    }
  }

  render() {
    const { classes, services, typesList } = this.props;
    const selectedUser = this.props.location.state
      ? this.props.location.state.user
      : null;

    return (
      <div className={classes.root}>
        <Sidebar />
        <div style={{ height: "100%", overflowY: "auto" }}>
          <AddUser
            typesList={typesList}
            selectedUser={selectedUser}
            services={services}
            requestAddUser={this.props.requestAddUser}
            requestUsers={this.props.requestUsers}
            requestTypes={this.props.requestFieldTypes}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  services: makeSelectServices(),
  typesList: makeSelectFieldTypes(),
});
const mapDispatchToProps = {
  requestAddUser,
  requestServices,
  requestUsers,
  requestFieldTypes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddUserPage));
