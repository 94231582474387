import { fromJS } from 'immutable/dist/immutable';
import { REMOVE_TOAST, SET_TOAST } from './constants';

const initialState = fromJS({
  toasts: [],
});

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOAST: {
      return state.set(
        'toasts',
        state.get('toasts').push(fromJS(action.toast)),
      );
    }

    case REMOVE_TOAST: {
      const toasts = state.get('toasts').toJS();
      return state.set(
        'toasts',
        fromJS(toasts.filter(toast => toast.id !== action.id)),
      );
    }

    default:
      return state;
  }
}
