import React, { Component } from "react";
import UsersTable from "../../components/UsersTable";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeSelectUsers } from "../PopupContainer/selector";
import { requestAddUser, requestDeleteUser } from "../AddUserPage/actions";
import UserActions from "../../components/UserActions";
import { requestUsers } from "../PopupContainer/action";
import history from "../../utils/history";
import { endPoints } from "../../config/end-points";
import Sidebar from "../../components/Sidebar";

const styles = {
  root: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    height: "100%",
  },
  body: {
    display: "flex",
    direction: "row",
    justifyContent: "center",
    height: "100%",
    padding: 25,
    overflow: "auto",
  },
};

class UserDashboard extends Component {
  state = {
    open: false,
    selectedUser: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      departments: [],
      role: "",
    },
    pageConfig: {
      page: 0,
      size: 1,
    },
  };

  componentWillMount() {
    //this.props.initDocumentsPage();
    this.props.requestUsers(this.state.pageConfig);
  }

  componentDidMount() {
    this.props.requestUsers(this.state.pageConfig);
  }

  initUser = (selectedUser) => {
    if (selectedUser) {
      return {
        id: selectedUser.id,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        username: selectedUser.userRole,
        email: selectedUser.email,
        departments: selectedUser.departments ? selectedUser.departments : [],
        role: selectedUser.role,
      };
    }
  };

  onAddUser = () => {
    history.push({
      pathname: endPoints.addUser,
    });
  };

  handlePageChange = (pageConfig) => {
    this.setState({ pageConfig: pageConfig });
    this.props.requestUsers(pageConfig);
  };

  render() {
    const { classes, usersList } = this.props;
    return (
      <div className={classes.root}>
        <Sidebar />
        <Grid container className={classes.body}>
          <Header
            buttonLabel="Ajouter un utilisateur"
            onCallToAction={this.onAddUser}
          />
          <UsersTable
            users={usersList}
            actions={(user) => (
              <UserActions
                onDeleteUser={this.props.requestDeleteUser}
                user={user}
              />
            )}
            handlePageConfigChange={this.handlePageChange}
          />
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = {
  requestDeleteUser,
  requestAddUser,
  requestUsers,
};

const mapStateToProps = createStructuredSelector({
  usersList: makeSelectUsers(),
});

UserDashboard.propTypes = {
  requestAddUser: PropTypes.func,
  requestDeleteUser: PropTypes.func,
  requestUsers: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserDashboard));
