import {fromJS} from "immutable";
import {SET_FIELD_TYPES} from "./constant";

export const initialState = fromJS({
    fieldTypes: [],
});

function fieldContainerReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FIELD_TYPES:
            return action.node
                ? state.set(action.node, fromJS(action.fieldTypes))
                : state.set('fieldTypes', fromJS(action.fieldTypes));
        default:
            return state;
    }
}

export default fieldContainerReducer;