import React, { Component } from "react";
import Button from "@material-ui/core/Button/Button";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu/Menu";
import { renderSelect } from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";

class MoreHorizButton extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      props: { items, disabled },
      state: { anchorEl },
      handleClick,
      handleClose,
    } = this;
    const open = Boolean(anchorEl);
    return (
      <>
        <Tooltip title="Actions" placement="top-start">
          <Button onClick={handleClick} disabled={disabled}>
            <MoreHoriz />
          </Button>
        </Tooltip>
        {open && (
          <Menu anchorEl={anchorEl} onClose={handleClose} open={open} style={{borderRadius: 4}}>
            {renderSelect(items, undefined, handleClose)}
          </Menu>
        )}
      </>
    );
  }
}

export default MoreHorizButton;
