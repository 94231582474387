import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import useStyles from "./style";

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const classess = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.order ? order : false}
            classes={{ stickyHeader: classess.stickyHeader }}
            style={headCell.parentStyle}
          >
            {headCell.order && (
              <TableSortLabel
                active={orderBy === headCell.order}
                direction={orderBy === headCell.order ? order : "asc"}
                onClick={createSortHandler(headCell.order)}
                className={classes.title}
              >
                {headCell.label}
              </TableSortLabel>
            )}
            <span className={classes.title}>
              {!headCell.order && headCell.label}
            </span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
