import {fromJS} from "immutable";
import {SET_DOCUMENTS_TO_VALIDATE} from "./constant";
import {SET_COUNT} from "../Dashboard/constants";

export const initialState = fromJS({
    documentsToValidate: [],
});

function documentsToValidateReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DOCUMENTS_TO_VALIDATE:
            return action.node
                ? state.set(action.node, fromJS(action.documentsToValidate))
                : state.set(
                    'documentsToValidate',
                    fromJS(action.documentsToValidate),
                );
        case SET_COUNT:
            return state.set('count', fromJS(action.count));
        default:
            return state;
    }
}

export default documentsToValidateReducer;