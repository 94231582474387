import Sidebar from "../../components/Sidebar";
import React, { Component } from "react";
import AddFieldType from "../../components/AddFieldType";
import withStyles from "@material-ui/core/styles/withStyles";
import { addFieldType, requestDeleteFieldType, editListValue } from "./action";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
};

class AddTypePage extends Component {
  render() {
    const { classes } = this.props;
    const selectedFieldType = this.props.location.state
      ? this.props.location.state.fieldType
      : null;
    return (
      <div className={classes.root}>
        <Sidebar />
        <div style={{ height: "100%", overflowY: "auto" }}>
          <AddFieldType
            addFieldType={this.props.addFieldType}
            editListValue={this.props.editListValue}
            requestDeleteFieldType={this.props.requestDeleteFieldType}
            type={selectedFieldType}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  editListValue,
  addFieldType,
  requestDeleteFieldType,
};

const mapStateToProps = createStructuredSelector({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddTypePage));
