import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Comment } from "../DocumentView/DocumentView";

const styles = {
  approveBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    marginLeft: "20px",
    padding: "0 25px 0 25px",
    width: "158px",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  resetBtn: {
    float: "right",
    padding: "0 25px 0 25px",
    width: "158px",
    height: "40px",
    boxShadow: "none",
  },
  fileContainer: {
    display: "flex",
    width: "100%",
    height: "184px",
    border: "1px dashed rgba(42, 105, 188, 0.37)",
    borderRadius: 8,
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  displayFile: {
    display: "flex",
    width: "100%",
    height: 65,
    borderRadius: 8,
    margin: "8px 15px",
    padding: "0px 8px",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#C5DCFA66 40%",
  },
  typography: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: "0.0075em",
    textAlign: "left",
  },
};

const Notes = ({ comments, comment, handleChange }) => (
  <>
    <FormControl style={{ margin: 16, width: "100%" }}>
        <TextField
          name="comment"
          key="reference"
          variant="outlined"
          label="Note"
          size="small"
          value={comment}
          onChange={handleChange}
        />
      </FormControl>
    <Comment comments={comments} />
  </>
);

export default withStyles(styles)(Notes);
