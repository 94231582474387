import {ADD_FIELD_TYPE, DELETE_FIELD_TYPE,EDIT_LIST_VALUE} from "./constant";

export const addFieldType = (fieldType, node) => ({
    type: ADD_FIELD_TYPE,
    fieldType,
    node
});
export const editListValue = (value) => ({
    type: EDIT_LIST_VALUE,
    value,
});

export const requestDeleteFieldType = (id, node) => ({
    type: DELETE_FIELD_TYPE,
    id,
    node
});