import {
    ADD_ATTACHMENT, ADD_DOCUMENT,
    REQUEST_DOCUMENT_FIELDS,
    REQUEST_DOCUMENT_TYPES,
    REQUEST_SERVICES,
    REQUEST_USERS, SET_DOCUMENT_FIELDS,
    SET_DOCUMENT_TYPES, SET_FILENAME,
    SET_SERVICES,
    SET_USERS
} from "./constants";
import {REQUEST_FIELD_TYPES} from "../AddFieldPage/constant";

export const requestServices = () => ({
    type : REQUEST_SERVICES,
});

export const requestUsers = (pageConfig) => ({
    type: REQUEST_USERS,
    pageConfig
});

export const requestDocumentTypes = (pageConfig) => ({
    type: REQUEST_DOCUMENT_TYPES,
    pageConfig
});

export const setServices = (services, node) => ({
    type: SET_SERVICES,
    services,
    node
});

export const setUsers = (users, node) => ({
    type: SET_USERS,
    users,
    node
});

export const setDocumentTypes = (documentTypes, node) => ({
    type: SET_DOCUMENT_TYPES,
    documentTypes,
    node
});

export const requestDocumentFields = (id, node) => ({
    type: REQUEST_DOCUMENT_FIELDS,
    id,
    node
});

export const setDocumentFields = (documentFields, node) => ({
    type: SET_DOCUMENT_FIELDS,
    documentFields,
    node,
});

export const addAttachment = (file) => ({
    type: ADD_ATTACHMENT,
    file,
});

export const addDocument = (document, close) => ({
    type: ADD_DOCUMENT,
    document,
    close,
});

export const setFileName = (filename) => ({
    type: SET_FILENAME,
    filename,
});

export const requestFieldTypes = () => ({
    type: REQUEST_FIELD_TYPES,
});