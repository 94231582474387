import isAuthenticated from "../../services/security/isAuthenticated";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../Login";
import PrivateRoute from "./PrivateRoute";
import ValidationBoard from "../ValidationBoard";
import AddUserPage from "../AddUserPage";
import AddServicePage from "../AddServicePage";
import UsersDashboard from "../UsersDashboard";
import history from "../../utils/history";
import { Router } from "react-router";
import AddTypePage from "../AddTypePage";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import AddNewDocumentTypePage from "../AddNewDocumentTypePage";
import DocumentTypesPage from "../DocumentTypesPage";
import DepartmentsPage from "../DepartmentsPage";
import TypesPage from "../TypesPage";
import Toast from "../Toast";
import Home from "../Home";
import Dashboard from "../Dashboard";
import Profile from "../Profile";
import { authUrl } from "../../utils/authConfig";
import DocumentViewPage from "../DocumentViewPage";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Noto Sans",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Noto Sans",
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
      },
      h6: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontSize: 14,
        alignItems: "center",
      },
      head: {
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.5) !important",
      },
      active: {
        color: "rgba(0, 0, 0, 0.5) !important",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
      },
    },
    MuiChip: {
      label: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        color: "#848484",
      },
    },
    MuiButton: {
      label: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        color: "inherit",
      },
      root: {
        textTransform: "initial",
        borderRadius: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderRadius: 8,
        },
      },
    },
    MuiTablePagination: {
      select: {
        color: "rgba(0, 0, 0, 0.5)",
      },
      caption: {
        color: "rgba(0, 0, 0, 0.5)",
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
      },
    },
  },
});

export default function App() {
  return (
    <Profile>
      <MuiThemeProvider theme={theme}>
        <>
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (isAuthenticated()) {
                    return <Redirect to="/home" />;
                  } else {
                    if (!window.location.href.includes("iframe")) {
                      window.location.href = authUrl;
                    }
                    return null;
                  }
                }}
              />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/home" component={Dashboard} />
              <PrivateRoute exact path="/document/detail" component={DocumentViewPage} />
              <PrivateRoute
                exact
                path="/validation"
                component={ValidationBoard}
              />
              <PrivateRoute exact path="/addUser" component={AddUserPage} />
              <PrivateRoute exact path="/service" component={AddServicePage} />
              <PrivateRoute exact path="/users" component={UsersDashboard} />
              <PrivateRoute exact path="/addType" component={AddTypePage} />
              <PrivateRoute
                exact
                path="/documentType"
                component={AddNewDocumentTypePage}
              />
              <PrivateRoute
                exact
                path="/documentTypes"
                component={DocumentTypesPage}
              />
              <PrivateRoute
                exact
                path="/services"
                component={DepartmentsPage}
              />
              <PrivateRoute exact path="/types" component={TypesPage} />
            </Switch>
            <Toast />
          </Router>
        </>
      </MuiThemeProvider>
    </Profile>
  );
}
