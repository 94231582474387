export const ratio = 20;
export const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "Utilisateur",
    order: "userName",
    parentStyle: { width: `${ratio}%` },
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    order: "action",
    parentStyle: { width: `${ratio}%` },
  },
  {
    id: "attribut",
    numeric: false,
    disablePadding: false,
    label: "Attribut",
    order: "attribut",
    parentStyle: { width: `${ratio}%` },
  },
  {
    id: "oldValue",
    numeric: false,
    disablePadding: false,
    label: "Ancienne valeur",
    order: "oldValue",
    parentStyle: { width: `${ratio}%` },
  },
  {
    id: "newValue",
    numeric: false,
    disablePadding: false,
    label: "Nouvelle valeur",
    order: "newValue",
    parentStyle: { width: `${ratio}%` },
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    order: "date",
    parentStyle: { width: `${ratio}%` },
  },
];
export const initFilters = {
  search: "",
};
