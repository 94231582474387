import { fromJS } from "immutable";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "./constants";
import loginDataStorageService from "../../services/security/loginDataStorageService";

const initialState = fromJS({
  loginFailed: false,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      loginDataStorageService.storeLoginData(action.loginResponse);
      return state.set("loginFailed", false);
    }
    case LOGIN_FAILURE: {
      return state.set("loginFailed", true);
    }
    default:
      return state;
  }
}

export default loginReducer;
