import {ADD_DOCUMENT_TYPE, DELETE_DOCUMENT_TYPE} from "./constants";

export const requestAddDocumentType = (documentType, node) => ({
    type: ADD_DOCUMENT_TYPE,
    documentType,
    node
});

export const requestDeleteDocumentType = (id, node) => ({
    type: DELETE_DOCUMENT_TYPE,
    id,
    node
});