import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import BootstrapInput from "../BootstrapInput";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const styles = {
  approveBtn: {
    float: "right",
    color: "#10277C !important",
    backgroundColor: "#EDF1F2 !important",
    display: "flex",
    padding: "0 25px 0 25px !important",
    height: 40,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  textField: {
    borderRadius: 8,
  },
  InputLabelSelect: {
    color: "#2F2F2F",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
  },
  InputLabelSelectFocused: {
    color: "#2F2F2F !important",
    fontSize: 12,
  },
  select: {
    margin: "0 !important",
  },
  selectIcon: {
    color: "#2F2F2F",
    top: 6,
  },
  reset: {
    textTransform: "capitalize",
    color: "#2F2F2F",
    height: 40,
    position: "relative",
  },
  noBorder: {
    border: "none",
  },
};

class Filter extends Component {
  state = {
    searchCriteria: this.props.filterCriteria?.find(
      (fc) => fc.id === "documentType"
    )
      ? { documentType: "" }
      : this.props.filterCriteria.reduce(
          (prev, f) => ({ ...prev, [f.id]: "" }),
          {}
        ),
    additionalFields: {},
  };

  initState = (searchCriteria) => {
    this.setState({
      searchCriteria: searchCriteria,
      additionalFields: {},
    });
  };

  initFilter = () => {
    this.initState(
      this.props.filterCriteria?.find((fc) => fc.id === "documentType")
        ? { documentType: "" }
        : this.props.filterCriteria.reduce(
            (prev, f) => ({ ...prev, [f.id]: "" }),
            {}
          )
    );
    this.props.handleFilterChange({});
    this.props.handleSearch(true);
  };

  handleFilterChange = (event) => {
    const searchCriteria = {
      ...(event.target.name === "documentType" ||
      event.target.name === "service"
        ? { service: this.state.searchCriteria["service"] }
        : this.state.searchCriteria),
      [event.target.name]: event.target.value,
    };

    if (event.target.name === "startDate") {
      searchCriteria[event.target.name] = event.target.value + 'T00:00:00+0000';
    }

    if (event.target.name === "endDate") {
      searchCriteria[event.target.name] = event.target.value + 'T23:59:59+0000';
    }

    this.setState({
      searchCriteria: searchCriteria,
      additionalFields:
        event.target.name === "documentType" ? {} : this.state.additionalFields,
    });
    this.props.handleFilterChange({
      ...searchCriteria,
      additionalFields:
        event.target.name === "documentType"
          ? {}
          : {
              ...Object.keys(this.state.additionalFields).reduce(
                (prev, curr) => {
                  let value = {};
                  if (this.state.additionalFields[curr]) {
                    value = {
                      [curr]:
                        typeof this.state.additionalFields[curr] === "object"
                          ? this.state.additionalFields[curr].name
                          : this.state.additionalFields[curr],
                    };
                  }
                  return {
                    ...prev,
                    ...value,
                  };
                },
                {}
              ),
            },
    });
  };
  handleFilterChangeAdditional = (event) => {
    const additionalFields = {
      ...this.state.additionalFields,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
            ? 1
            : 0
          : event.target.value,
    };

    this.setState({
      additionalFields: additionalFields,
    });
    this.props.handleFilterChange({
      ...this.state.searchCriteria,
      additionalFields: {
        ...Object.keys(additionalFields).reduce((prev, curr) => {
          let value = {};
          if (additionalFields[curr]) {
            value = {
              [curr]:
                typeof additionalFields[curr] === "object"
                  ? additionalFields[curr].name
                  : additionalFields[curr],
            };
          }
          return {
            ...prev,
            ...value,
          };
        }, {}),
      },
    });
  };
  handleFilterChangeValueAdditional = (name, value) => {
    const additionalFields = {
      ...this.state.additionalFields,
      [name]: value,
    };

    this.setState({
      additionalFields: additionalFields,
    });
    this.props.handleFilterChange({
      ...this.state.searchCriteria,
      additionalFields: {
        ...Object.keys(additionalFields).reduce((prev, curr) => {
          let value = {};
          if (additionalFields[curr]) {
            value = {
              [curr]:
                typeof additionalFields[curr] === "object"
                  ? additionalFields[curr].code
                    ? additionalFields[curr].code
                    : additionalFields[curr].name
                  : additionalFields[curr],
            };
          }
          return {
            ...prev,
            ...value,
          };
        }, {}),
      },
    });
  };
  renderField = (field) => {
    if (field.type.type === "BOOLEAN") {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={3}
        >
          <FormControl style={{ margin: "10px 16px 16px 0", width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    parseInt(this.state.additionalFields[field.name], 10) === 1
                  }
                  onChange={(event) => this.handleFilterChangeAdditional(event)}
                  name={field.name}
                  color="primary"
                />
              }
              label={field.name}
            />
          </FormControl>
        </Grid>
      );
    } else if (field.type.type === "CLIENT") {
      return (
        <>
          {" "}
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={3}
          >
            <FormControl
              style={{ margin: 16, width: "100%", marginLeft: 0 }}
              variant="outlined"
            >
              <Autocomplete
                id={field.id}
                name={field.name}
                value={this.state.additionalFields[field.name] || null}
                options={field.type.fieldTypeValues}
                getOptionLabel={(option) => option.code}
                size="small"
                // handleSecondaryFieldsChange expects event target name and event target value
                // but autocomplete doesn't provide those values, instead it gives you the newValue as a second param
                onChange={(_, newValue) =>
                  this.handleFilterChangeValueAdditional(field.name, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={field.name}
                    variant="outlined"
                  />
                )}
                style={{
                  alignItems: "center",
                  width: "100%",
                }}
              />
            </FormControl>
          </Grid>
          {this.state.additionalFields[field.name] && (
            <>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
                lg={3}
              >
                <FormControl
                  style={{ margin: 16, width: "100%", marginLeft: 0 }}
                >
                  <TextField
                    variant="outlined"
                    label="Ville"
                    key={field.name}
                    size="small"
                    value={this.state.additionalFields[field.name]?.city}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
                lg={3}
              >
                <FormControl
                  style={{ margin: 16, width: "100%", marginLeft: 0 }}
                >
                  <TextField
                    variant="outlined"
                    label="Intitulé client"
                    size="small"
                    value={this.state.additionalFields[field.name]?.name}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </>
      );
    }
    if (
      (["INT", "DECIMAL", "STRING"].includes(field.type.type) &&
        field.type.fieldTypeValues &&
        field.type.fieldTypeValues.length === 0) ||
      ["PHONE", "EMAIL"].includes(field.type.type)
    ) {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          xs={3}
        >
          <FormControl style={{ margin: 16, width: "100%", marginLeft: 0 }}>
            <TextField
              variant="outlined"
              label={field.name}
              name={field.name}
              key={field.name}
              size="small"
              value={this.state.additionalFields[field.name] || ""}
              onChange={(event) => this.handleFilterChangeAdditional(event)}
            />
          </FormControl>
        </Grid>
      );
    } else if (field.type === "DATE" || field.type.type === "DATE") {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          xs={3}
        >
          <FormControl style={{ margin: 16, width: "100%", marginLeft: 0 }}>
            <TextField
              id={field.id}
              name={field.name}
              variant="outlined"
              label={field.name}
              type="date"
              defaultValue={new Date()}
              value={this.state.additionalFields[field.name] || ""}
              onChange={(event) => this.handleFilterChangeAdditional(event)}
              size="small"
              InputLabelProps={{
                className: this.props.classes.typography,
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          xs={3}
        >
          <FormControl
            style={{ margin: 16, width: "100%", marginLeft: 0 }}
            variant="outlined"
          >
            <Autocomplete
              id={field.id}
              name={field.name}
              value={this.state.additionalFields[field.name] || null}
              options={field.type.fieldTypeValues}
              getOptionLabel={(option) => option.name}
              size="small"
              // handleSecondaryFieldsChange expects event target name and event target value
              // but autocomplete doesn't provide those values, instead it gives you the newValue as a second param
              onChange={(_, newValue) =>
                this.handleFilterChangeValueAdditional(field.name, newValue)
              }
              renderInput={(params) => (
                <TextField {...params} label={field.name} variant="outlined" />
              )}
              style={{
                alignItems: "center",
                width: "100%",
              }}
            />
          </FormControl>
        </Grid>
      );
    }
  };
  render() {
    const { classes } = this.props;
    const { searchCriteria } = this.state;
    const documentType = this.props.filterCriteria?.find(
      (fc) => fc.id === "documentType"
    );
    const service = this.props.filterCriteria?.find(
      (fc) => fc.id === "service" && fc.select
    );
    const withoutDocumentType = this.props.filterCriteria?.filter(
      (fc) => fc.id !== "documentType" && fc.id !== "service"
    );

    return (
      <Grid container>
        <Grid
          xs={12}
          container
          item
          style={{
            alignItems: "center",
          }}
        >
          <Grid item xs={2} style={{ marginRight: 8 }}>
            <FormControl style={{ width: "100%", borderRadius: 8 }}>
              <TextField
                variant="outlined"
                label="Search"
                value={searchCriteria.search}
                style={{ borderRadius: 8 }}
                type="text"
                onChange={(event) => this.handleFilterChange(event)}
                name="search"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: classes.textField,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ display: "flex" }} xs={4}>
            <Button
              className={classes.approveBtn}
              onClick={() => this.props.handleSearch()}
              style={{ marginRight: 8 }}
            >
              <FilterListIcon style={{ marginRight: 8 }} />
              <p
                style={{
                  fontFamily: "Noto Sans",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Filtrer
              </p>
            </Button>
            <Button onClick={() => this.initFilter()} className={classes.reset}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 10,
          }}
        >
          {service && (
            <FormControl
              variant="outlined"
              style={{ width: 150 }}
              key={service?.name}
            >
              <InputLabel
                classes={{
                  root: classes.InputLabelSelect,
                  focused: classes.InputLabelSelectFocused,
                }}
              >
                {service?.name}
              </InputLabel>
              <Select
                autoWidth
                input={<BootstrapInput style={{ marginLeft: 0 }} />}
                onChange={(event) => this.handleFilterChange(event)}
                label={service?.name}
                name={service?.id}
                value={searchCriteria[service?.id] || ""}
                classes={{ root: classes.select, icon: classes.selectIcon }}
              >
                {this.props &&
                  this.props[service?.propsName] &&
                  this.props[service?.propsName].map((criteriaField) => (
                    <MenuItem value={criteriaField.id} key={criteriaField.id}>
                      {criteriaField[service?.displayName]
                        ? service?.displayName === "username"
                          ? criteriaField["firstName"] +
                            " " +
                            criteriaField["lastName"]
                          : criteriaField[service?.displayName]
                        : criteriaField}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {documentType && searchCriteria[service?.id] && (
            <FormControl
              variant="outlined"
              style={{ width: 150 }}
              key={documentType?.name}
            >
              <InputLabel
                classes={{
                  root: classes.InputLabelSelect,
                  focused: classes.InputLabelSelectFocused,
                }}
              >
                {documentType?.name}
              </InputLabel>
              <Select
                autoWidth
                input={<BootstrapInput style={{ marginLeft: 0 }} />}
                onChange={(event) => this.handleFilterChange(event)}
                label={documentType?.name}
                name={documentType?.id}
                value={searchCriteria[documentType?.id] || ""}
                classes={{ root: classes.select, icon: classes.selectIcon }}
              >
                {this.props &&
                  this.props[documentType?.propsName] &&
                  this.props[documentType?.propsName]
                    .filter(
                      (type) =>
                        type.department.id === searchCriteria[service?.id]
                    )
                    .map((criteriaField) => (
                      <MenuItem value={criteriaField.id} key={criteriaField.id}>
                        {criteriaField[documentType?.displayName]
                          ? documentType?.displayName === "username"
                            ? criteriaField["firstName"] +
                              " " +
                              criteriaField["lastName"]
                            : criteriaField[documentType?.displayName]
                          : criteriaField}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          )}
          {((withoutDocumentType && searchCriteria[documentType?.id]) ||
            !documentType) &&
            withoutDocumentType.map((criteria) => (
              <FormControl
                variant="outlined"
                style={{ width: 150 }}
                key={criteria.name}
              >
                { !criteria.isDate ? (<>
                  <InputLabel
                  classes={{
                    root: classes.InputLabelSelect,
                    focused: classes.InputLabelSelectFocused,
                  }}
                >
                  {criteria.name}
                </InputLabel>
                <Select
                  autoWidth
                  input={<BootstrapInput style={{ marginLeft: 0 }} />}
                  onChange={(event) => this.handleFilterChange(event)}
                  label={criteria.name}
                  name={criteria.id}
                  value={searchCriteria[criteria.id] || ""}
                  classes={{ root: classes.select, icon: classes.selectIcon }}
                >
                  {this.props &&
                    this.props[criteria.propsName] &&
                    this.props[criteria.propsName].map((criteriaField) => (
                      <MenuItem value={criteriaField.id} key={criteriaField.id}>
                        {criteriaField[criteria.displayName]
                          ? criteria.displayName === "username"
                            ? criteriaField["firstName"] +
                              " " +
                              criteriaField["lastName"]
                            : criteriaField[criteria.displayName]
                          : criteriaField}
                      </MenuItem>
                    ))}
                </Select></>) : 
                (<FormControl style={{ margin: 8, width: "100%", marginLeft: 0 }}>
                  <TextField
                    variant="outlined"
                    type="date"
                    placeholder={criteria.name}
                    label={criteria.name}
                    name={criteria.id}
                    size="small"
                    value={searchCriteria[criteria.id]?.split("T")[0] || ""}
                    onChange={(event) => this.handleFilterChange(event)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { backgroundColor: '#EDF1F2', borderRadius: 10 },
                      classes: { notchedOutline: classes.noBorder }
                    }}
                  />
              </FormControl>)}
              </FormControl>
            ))}
          {documentType &&
            this.props[documentType.propsName] &&
            searchCriteria[documentType.id] && (
              <Grid container>
                {this.props[documentType.propsName]
                  .find((p) => p.id === searchCriteria[documentType.id])
                  ?.additionalFields?.map((criteria) =>
                    this.renderField(criteria)
                  )}
              </Grid>
            )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Filter);
