import { createSelector } from 'reselect';

const selectToast = state => state.get('toasts');

const makeSelectToastsList = () =>
  createSelector(
    selectToast,
    toasts => (toasts.get('toasts') ? toasts.get('toasts').toJS() : []),
  );

export { makeSelectToastsList };
