import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReferential = state => state.get('docPopup', initialState);

const selectDocumentTypes = createSelector(
    selectReferential,
    docPopupState =>
        docPopupState.get('documentTypes')
            ? docPopupState
                .get('documentTypes')
                .toJS()
            : [],
);

const selectServices = createSelector(
    selectReferential,
    docPopupState =>
        docPopupState.get('services')
            ? docPopupState
                .get('services')
                .toJS()
            : [],
);

const selectUsers = createSelector(
    selectReferential,
    docPopupState =>
        docPopupState.get('users')
            ? docPopupState
                .get('users')
                .toJS()
            : [],
);


const selectDocumentFields = createSelector(
    selectReferential,
    docPopupState =>
        docPopupState.get('documentFields')
            ? docPopupState
                .get('documentFields')
                .toJS()
            : [],
);

    const selectFilename = createSelector(
    selectReferential,
    docPopupState =>
        docPopupState.get('filename')
            ? docPopupState
                .get('filename')
                .toJS()
            : [],
);
export const makeSelectServices = () => selectServices;
export const makeSelectDocumentTypes = () => selectDocumentTypes;
export const makeSelectUsers = () => selectUsers;
export const makeSelectDocumentFields = () => selectDocumentFields;
export const makeSelectFilename = () => selectFilename;