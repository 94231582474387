import {ADD_DOCUMENT_TYPE, REQUEST_SERVICES, REQUEST_FIELD_TYPES} from "./constant";
import {SET_FIELD_TYPES} from "../AddFieldPage/constant";

export const requestAddDocumentType = (documentType, node) => ({
    type: ADD_DOCUMENT_TYPE,
    documentType,
    node
});

export const requestServices = () => ({
    type : REQUEST_SERVICES,
});

export const requestFieldTypes = () => ({
    type: REQUEST_FIELD_TYPES,
});

export const setFieldTypes = (fieldTypes, node) => ({
    type: SET_FIELD_TYPES,
    fieldTypes,
    node
});