import {ADD_DEPARTMENT, DELETE_DEPARTMENT} from "./constants";

export const requestAddDepartment = (department) => ({
    type: ADD_DEPARTMENT,
    department
});


export const requestDeleteDepartment = (id, node) => ({
    type: DELETE_DEPARTMENT,
    id,
    node
});