import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import DocumentTypesTable from "../../components/DocumentTypesTable";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeSelectDocumentTypes } from "../PopupContainer/selector";
import { requestAddDocumentType, requestDeleteDocumentType } from "../AddDocumentTypePage/action";
import { requestDocumentTypes } from "../PopupContainer/action";
import history from "../../utils/history"
import { endPoints } from "../../config/end-points";
import DocumentTypeActions from "../../components/DocumentTypesTable/DocumentTypeActions";



const styles = {
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        height: '100%',
    },
    body: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        height: '100%',
        padding: 25,
        overflow: 'scroll',
    },
};

class DocumentTypesPage extends Component {

    state = {
        open: false,
        selectedDocumentType: {
            id: "",
            name: "",
            fieldTypes: [],
            department: {},
        },
        pageConfig: {
            page: 0,
            size: 1,
        },
    };

    componentWillMount() {
        this.props.requestDocumentTypes(this.state.pageConfig);
    }

    componentDidMount() {
        this.props.requestDocumentTypes(this.state.pageConfig);
    }

    initDocumentType = (selectedDocumentType) => {
        if (selectedDocumentType) {
            return {
                id: selectedDocumentType.id,
                name: selectedDocumentType.name,
                department: selectedDocumentType.department,
                fieldTypes: selectedDocumentType.fieldTypes ? selectedDocumentType.fieldTypes : [],
            };

        }
    };

    onAddDocumentType = () => {
        history.push({
            pathname: endPoints.addDocumentType,
        });
    };

    handlePageChange = (pageConfig) => {
        this.setState({ pageConfig: pageConfig });
        this.props.requestDocumentTypes(pageConfig);
    };

    render() {
        const { classes, documentTypesList } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar />
                <Grid container className={classes.body}>
                    <Header buttonLabel='Ajouter un type de document' onCallToAction={this.onAddDocumentType} />
                    <DocumentTypesTable
                        documentTypesList={documentTypesList}
                        actions={(documentType) =>
                            <DocumentTypeActions
                                requestDeleteDocumentType={this.props.requestDeleteDocumentType}
                                requestDocumentTypes={this.props.requestDocumentTypes}
                                documentType={documentType}
                            />}
                        handlePageConfigChange={this.handlePageChange}
                    />
                </Grid>
            </div>
        );
    }

}

const mapDispatchToProps = {
    requestDocumentTypes,
    requestDeleteDocumentType,
    requestAddDocumentType
};

const mapStateToProps = createStructuredSelector({
    documentTypesList: makeSelectDocumentTypes(),
});


DocumentTypesPage.propTypes = {
    requestAddDocumentType: PropTypes.func,
    requestDocumentTypes: PropTypes.func,
    requestDeleteDocumentType: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DocumentTypesPage));