import React from "react";
import {ReactComponent as EditIcon} from '../../images/icons/edit.svg';
import {ReactComponent as DeleteIcon} from '../../images/icons/trash.svg';
import MoreHorizButton from "../../common/MoreHorizButton";
import history from "../../utils/history";
import {endPoints} from "../../config/end-points";

function DepartmentActions(props) {
    const { department } = props;



    const handleUpdate= (department) => {

        history.push({
            pathname: endPoints.addDepartment,
            state: {
                department: department,
            },
        });
    };

    return (
        <MoreHorizButton
            items={[
                {
                    key: 1,
                    value: "Modifier",
                    onClick: () => {handleUpdate(department)},
                    icon: <EditIcon style={{color: '#10277C'}}/>,
                },
                {
                    key: 2,
                    value: "Supprimer",
                    onClick: () => {
                        props.requestDeleteDepartment(department.id);
                        props.requestDepartments();
                    },
                    icon: <DeleteIcon style={{color: '#10277C'}}/>,
                },
            ]}
        />
    );

}

export default DepartmentActions;
