import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import history from "../../utils/history"
import { endPoints } from "../../config/end-points";
import FieldTypesTable from "../../components/FieldTypesTable";
import { makeSelectFieldTypes } from "../AddFieldPage/selector";
import { requestFieldTypes } from "../AddFieldPage/action";
import { addFieldType, requestDeleteFieldType } from "../AddTypePage/action";
import FieldTypeActions from "../../components/FieldTypesTable/FieldTypeActions";




const styles = {
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        height: '100%',
    },
    body: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        height: '100%',
        padding: 25,
        overflow: 'scroll',
    },
};

class TypesPage extends Component {

    state = {
        open: false,
        selectedType: {
            id: '',
            name: '',
            type: '',
            values: [],
        },
        pageConfig: {
            page: 0,
            size: 1,
        },
    };

    componentWillMount() {
        this.props.requestFieldTypes(this.state.pageConfig);
    }

    componentDidMount() {
        this.props.requestFieldTypes(this.state.pageConfig);
    }

    onAddFieldType = () => {
        history.push({
            pathname: endPoints.addFieldType,
        });
    };

    handlePageChange = (pageConfig) => {
        this.setState({ pageConfig: pageConfig });
        this.props.requestFieldTypes(pageConfig);
    };

    render() {
        const { classes, typesList } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar />
                <Grid container className={classes.body}>
                    <Header buttonLabel='Ajouter un type de champ' onCallToAction={this.onAddFieldType} />
                    <FieldTypesTable
                        typesList={typesList}
                        actions={(fieldType) =>
                            <FieldTypeActions
                                requestDeleteFieldType={this.props.requestDeleteFieldType}
                                requestFieldTypes={this.props.requestFieldTypes}
                                fieldType={fieldType}
                            />}
                        handlePageConfigChange={this.handlePageChange}
                    />
                </Grid>
            </div>
        );
    }

}

const mapDispatchToProps = {
    requestDeleteFieldType,
    addFieldType,
    requestFieldTypes
};

const mapStateToProps = createStructuredSelector({
    typesList: makeSelectFieldTypes(),
});


TypesPage.propTypes = {
    requestDeleteType: PropTypes.func,
    addFieldType: PropTypes.func,
    requestFieldTypes: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TypesPage));