import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';

import Close from '@material-ui/icons/Close';

const styles = () => ({
    button: {
        width: 37,
        height: 37,
        minWidth: 25,
        minHeight: 25,
        float: 'right',
        borderRadius: '50%',
    },
    icon: {
        width: 18,
    },
});

class CloseButton extends Component {
    render() {
        const { classes, style, id, handleClose } = this.props;
        return (
            <Button
                id={id}
                className={classes.button}
                onClick={()=>handleClose()}
                style={style}
            >
                <Close
                    className={classes.icon}
                />
            </Button>
        );
    }
}

export default withStyles(styles)(CloseButton);
