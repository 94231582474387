import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { USER_STORAGE } from "../../common/constant";
import { fileFormatsTypes } from "../../containers/App/constants";
import { ReactComponent as Pdf } from "../../images/icons/pdf.svg";
import img from "../../images/picture.png";
import { get } from "../../services/appStorage";
import DropZoneComp from "../DropDownFile/FileComponent";
import Popup from "./index";
import Notes from "./Notes";

const styles = {
  approveBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    marginLeft: "20px",
    padding: "0 25px 0 25px",
    width: "158px",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  resetBtn: {
    float: "right",
    padding: "0 25px 0 25px",
    width: "158px",
    height: "40px",
    boxShadow: "none",
  },
  fileContainer: {
    display: "flex",
    width: "100%",
    height: "184px",
    border: "1px dashed rgba(42, 105, 188, 0.37)",
    borderRadius: 8,
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  displayFile: {
    display: "flex",
    width: "100%",
    height: 65,
    borderRadius: 8,
    margin: "8px 15px",
    padding: "0px 8px",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#C5DCFA66 40%",
  },
  typography: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: "0.0075em",
    textAlign: "left",
  },
};

class DocPopup extends Component {
  state = {
    document: {
      documentTypeId: null,
      amount: null,
      id: null,
      reference: null,
      department: null,
      validators: [],
      fields: {},
      files: [],
      comment: '',
    },
    secondaryFields: [],
    selectedValidators: [],
    currentDocTypes: [],
  };

  componentDidMount() {
    const { selectedDocument, secondaryFields, selectedValidators } =
      this.props;
    //      this.setState({
    //        secondaryFields: secondaryFields,
    //      });

    if (selectedDocument) {
      const selectedDepartment = selectedDocument.department;
      const documentTypes =
        this.props.documentTypes &&
        this.props.documentTypes.filter(
          (type) => type.department.id === selectedDepartment
        );
      this.setState({ currentDocTypes: documentTypes });
      this.setState({ document: selectedDocument });
    }
    if (selectedValidators) {
      this.setState({ selectedValidators: selectedValidators });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.secondaryFields &&
      this.props.secondaryFields !== prevProps.secondaryFields
    ) {
      this.setState({ secondaryFields: this.props.secondaryFields });
      // TODO SET FIELDS IN STATE DOC
    }
    if (
      this.props.selectedDocument &&
      prevProps.selectedDocument !== this.props.selectedDocument
    ) {
      this.setState({ document: this.props.selectedDocument });
    }
    if (
      this.props.selectedValidators &&
      prevProps.selectedValidators !== this.props.selectedValidators
    ) {
      this.setState({ selectedValidators: this.props.selectedValidators });
    }
  }

  renderField = (field) => {
    if (field.type.type === "BOOLEAN") {
      if (
        ![0, 1].includes(parseInt(this.state.document.fields[field.name], 10))
      ) {
        this.setState({
          document: {
            ...this.state.document,
            fields: { ...this.state.document.fields, [field.name]: 0 },
          },
        });
      }
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={6}
        >
          <FormControl style={{ margin: 16, width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    parseInt(this.state.document.fields[field.name], 10) === 1
                  }
                  onChange={this.handleSecondaryFieldsChange}
                  name={field.name}
                  color="primary"
                />
              }
              label={field.name}
            />
          </FormControl>
        </Grid>
      );
    } else if (field.type.type === "CLIENT") {
      return (
        <>
          {" "}
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={6}
          >
            <FormControl
              style={{ margin: 16, width: "100%" }}
              variant="outlined"
            >
              <Autocomplete
                id={field.id}
                name={field.name}
                value={
                  typeof this.state.document.fields[field.name] === "object"
                    ? this.state.document.fields[field.name]
                    : field.type.fieldTypeValues?.find(
                        (ft) =>
                          ft.code === this.state.document.fields[field.name]
                      )
                }
                options={field.type.fieldTypeValues}
                getOptionLabel={(option) => option.code}
                size="small"
                // handleSecondaryFieldsChange expects event target name and event target value
                // but autocomplete doesn't provide those values, instead it gives you the newValue as a second param
                onChange={(_, newValue) =>
                  this.handleSecondaryFieldsChange({
                    target: { name: field.name, value: newValue },
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={field.name}
                    variant="outlined"
                  />
                )}
                style={{
                  alignItems: "center",
                  width: "100%",
                }}
              />
            </FormControl>
          </Grid>
          {this.state.document.fields[field.name] && (
            <>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
                lg={6}
              >
                <FormControl style={{ margin: 16, width: "100%" }}>
                  <TextField
                    variant="outlined"
                    label="Ville"
                    key={field.name}
                    size="small"
                    value={
                      typeof this.state.document.fields[field.name] === "object"
                        ? this.state.document.fields[field.name]?.city
                        : field.type.fieldTypeValues?.find(
                            (ft) =>
                              ft.code === this.state.document.fields[field.name]
                          )?.city
                    }
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
                lg={6}
              >
                <FormControl style={{ margin: 16, width: "100%" }}>
                  <TextField
                    variant="outlined"
                    label="Intitulé client"
                    size="small"
                    value={
                      typeof this.state.document.fields[field.name] === "object"
                        ? this.state.document.fields[field.name]?.name
                        : field.type.fieldTypeValues?.find(
                            (ft) =>
                              ft.code === this.state.document.fields[field.name]
                          )?.name
                    }
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </>
      );
    } else if (
      (["INT", "DECIMAL", "STRING"].includes(field.type.type) &&
        field.type.fieldTypeValues &&
        field.type.fieldTypeValues.length === 0) ||
      ["PHONE", "EMAIL"].includes(field.type.type)
    ) {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={6}
        >
          <FormControl style={{ margin: 16, width: "100%" }}>
            <TextField
              variant="outlined"
              label={field.name}
              name={field.name}
              key={field.name}
              size="small"
              value={this.state.document.fields[field.name]}
              onChange={this.handleSecondaryFieldsChange}
            />
          </FormControl>
        </Grid>
      );
    } else if (field.type === "DATE" || field.type.type === "DATE") {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={6}
        >
          <FormControl style={{ margin: 16, width: "100%" }}>
            <TextField
              id={field.id}
              name={field.name}
              variant="outlined"
              label={field.name}
              type="date"
              defaultValue={new Date()}
              value={this.state.document.fields[field.name]}
              onChange={this.handleSecondaryFieldsChange}
              size="small"
              InputLabelProps={{
                className: this.props.classes.typography,
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={6}
        >
          <FormControl style={{ margin: 16, width: "100%" }} variant="outlined">
            <Autocomplete
              id={field.id}
              name={field.name}
              value={
                typeof this.state.document.fields[field.name] === "object"
                  ? this.state.document.fields[field.name]
                  : field.type.fieldTypeValues?.find(
                      (ft) => ft.name === this.state.document.fields[field.name]
                    )
              }
              options={field.type.fieldTypeValues}
              getOptionLabel={(option) => option.name}
              size="small"
              // handleSecondaryFieldsChange expects event target name and event target value
              // but autocomplete doesn't provide those values, instead it gives you the newValue as a second param
              onChange={(_, newValue) =>
                this.handleSecondaryFieldsChange({
                  target: { name: field.name, value: newValue },
                })
              }
              renderInput={(params) => (
                <TextField {...params} label={field.name} variant="outlined" />
              )}
              style={{
                alignItems: "center",
                width: "100%",
              }}
            />
          </FormControl>
        </Grid>
      );
    }
  };

  renderSecondaryFields = (secondaryFields) => {
    if (secondaryFields && secondaryFields.length !== 0) {
      return (
        <Grid container direction="row">
          <Grid item lg={12}>
            <Typography
              align="left"
              style={{ color: "#848484", marginLeft: 16 }}
            >
              Champs Additionnels
            </Typography>
          </Grid>
          {secondaryFields &&
            secondaryFields.map((field) => this.renderField(field))}
        </Grid>
      );
    } else if (
      this.props.selectedDocument &&
      this.props.selectedDocument.documentTypeId
    ) {
      this.props.handleDocumentTypeChange(
        this.props.selectedDocument.documentTypeId
      );
      return null;
    } else {
      return null;
    }
  };

  handleChange = (event) => {
    let document = {
      ...this.state.document,
      [event.target.name]: event.target.value,
    };
    this.setState({ document: document });
  };

  handleValidatorsChange = (event) => {
    let validators = this.state.document.validators;
    let selectedValidators = this.state.selectedValidators;
    if (validators && !validators.includes(event.target.value.id)) {
      validators.push(event.target.value.id);
      selectedValidators[event.target.value.id] = event.target.value;
      const document = {
        ...this.state.document,
        validators: validators,
      };
      this.setState({
        document: document,
        selectedValidators: selectedValidators,
      });
    }
  };

  handleSecondaryFieldsChange = (event) => {
    let fields = this.state.document.fields;
    fields[event.target.name] =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;
    this.setState({ document: { ...this.state.document, fields: fields } });
  };

  onPrimaryAction = () => {
    let fields = {};
    Object.keys(this.state.document.fields).forEach(
      (key) =>
        (fields = {
          ...fields,
          [key]: this.state.document.fields[key]?.name
            ? this.state.document.fields[key]?.code
              ? this.state.document.fields[key]?.code
              : this.state.document.fields[key]?.name
            : this.state.document.fields[key],
        })
    );
     
    this.props.requestAddDocument(
      { ...this.state.document, fields },
      this.props.handleClose
    );
  };

  handleFileDisplay = (file) => {
    this.setState({ document: { ...this.state.document, files: [...this.state.document.files,file[0]] } });
  };

  renderFooter = (props) => {
    const { classes } = this.props;
    return (
      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item style={{ margin: "0px 25px" }}>
          <Button
            id="reset-button"
            className={classes.resetBtn}
            onClick={props.handleClose}
          >
            <Typography style={{ fontWeight: 400 }}>Annuler</Typography>
          </Button>
        </Grid>
        <Button
          id="save-doc-button"
          variant="contained"
          className={classes.approveBtn}
          onClick={this.onPrimaryAction}
        >
          Enregistrer
        </Button>
      </Grid>
    );
  };

  onDeleteFile = (index) => {
    const files = this.state.document.files.splice(index,1);
    this.setState({ document: { ...this.state.document, file: files } });
  };

  onSelectDepartment = (event) => {
    this.handleChange(event);
    const selectedDepartment = event.target.value;
    const documentTypes =
      this.props.documentTypes &&
      this.props.documentTypes.filter(
        (type) => type.department.id === selectedDepartment
      );
    this.setState({ currentDocTypes: documentTypes });
    //    this.setState({ secondaryFields: [] });
    this.props.handleDocumentTypeChange(null);
    this.setState(
      {
        document: { ...this.state.document, fields: {}, documentTypeId: null },
      },
      () => this.handleChange(event)
    );
  };

  handleDocumentTypeChange = (event) => {
    this.props.handleDocumentTypeChange(event.target.value);
    this.setState({ document: { ...this.state.document, fields: {} } }, () =>
      this.handleChange(event)
    );
  };

  handleDeleteChip = (id) => {
    let validators = this.state.document.validators;
    let oldSelectedValidators = this.state.selectedValidators;
    let newSelectedValidators = [];

    const index = validators.indexOf(id);
    if (index > -1) {
      validators.splice(index, 1);
      newSelectedValidators = oldSelectedValidators.filter(
        (elt) => elt.id !== id
      );
    }
    this.setState({
      document: { ...this.state.document, validators: validators },
      selectedValidators: newSelectedValidators,
    });
  };

  render() {
    const { open, handleClose, classes, selectedDocument } = this.props;
    const { document, selectedValidators, currentDocTypes } = this.state;
    const user = get(USER_STORAGE);
    return (
      <Popup
        title="Nouveau document"
        fullWidth={true}
        open={open}
        handleClose={handleClose}
        footer={this.renderFooter({ handleClose: handleClose })}
      >
        <Grid container direction="row">
          <Grid item lg={12}>
            <Typography
              align="left"
              style={{ color: "#848484", marginLeft: 16 }}
            >
              Champs initiaux
            </Typography>
          </Grid>
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={6}
          >
            <FormControl style={{ margin: 16, width: "100%" }}>
              <TextField
                disabled={true}
                name="reference"
                key="reference"
                variant="outlined"
                label="ID de pièce"
                size="small"
                defaultValue={selectedDocument && selectedDocument.id}
                value={document && document.id}
                onChange={this.handleChange}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={6}
          >
            <FormControl
              variant="outlined"
              style={{ margin: 16, width: "100%" }}
            >
              <InputLabel style={{ top: -8 }}>Services</InputLabel>
              <Select
                name="department"
                key="department"
                size="small"
                defaultValue={selectedDocument && selectedDocument.department}
                onChange={this.onSelectDepartment}
                label="Services"
                margin="dense"
                style={{
                  alignItems: "center",
                  width: "100%",
                  borderRadius: 8,
                }}
              >
                {this.props.services &&
                  this.props.services
                    .filter(
                      (s) =>
                        user.role === "ROLE_ADMIN" ||
                        user.departments
                          .filter((d) => d.permission === "WRITE")
                          .map((d) => d.department?.id)
                          .includes(s.id)
                    )
                    .map((service) => (
                      <MenuItem value={service.id}>{service.name}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={6}
          >
            <FormControl
              style={{ margin: 16, width: "100%" }}
              variant="outlined"
            >
              <InputLabel style={{ top: -8 }}>Type de document</InputLabel>
              <Select
                name="documentTypeId"
                key="documentTypeId"
                value={document && document.documentTypeId}
                onChange={this.handleDocumentTypeChange}
                label="Type de document"
                style={{
                  alignItems: "center",
                  width: "100%",
                  borderRadius: 8,
                }}
                margin="dense"
              >
                {this.state.currentDocTypes &&
                  this.state.currentDocTypes.map((elt) => (
                    <MenuItem value={elt.id}>{elt.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={6}
            id="validators"
          >
            <FormControl
              style={{ margin: 16, width: "100%" }}
              variant="outlined"
            >
              <InputLabel style={{ top: -8 }}>Validateurs</InputLabel>
              <Select
                name="validators"
                key="validators"
                value={
                  document &&
                  document.validators &&
                  document.validators[document.validators.length - 1]
                }
                defaultValue={selectedDocument && selectedDocument.validators}
                onChange={this.handleValidatorsChange}
                label="Services"
                margin="dense"
                style={{
                  alignItems: "center",
                  width: "100%",
                  borderRadius: 8,
                }}
              >
                {this.props.users &&
                  this.props.users.map((user) => (
                    <MenuItem value={user}>
                      {user.firstName + " " + user.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {document && document.validators && document.validators.length !== 0 && (
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 16px",
                height: 56,
                maxWidth: "fit-content",
              }}
              lg={6}
              id="selectedValidators"
            >
              {selectedValidators &&
                selectedValidators.map((user) => (
                  <li style={{ listStyleType: "none", margin: 2 }}>
                    <Chip
                      label={user.firstName + " " + user.lastName}
                      onDelete={() => this.handleDeleteChip(user.id)}
                    />
                  </li>
                ))}
            </Grid>
          )}
          {this.renderSecondaryFields(this.state.secondaryFields)}
          <Grid item lg={12}>
            <Typography
              align="left"
              style={{ color: "#848484", margin: "16px 0px 0px 16px" }}
            >
              Chargement du document
            </Typography>
          </Grid>
          <Grid item className={classes.fileContainer} lg={12}>
            <DropZoneComp onFileDrop={this.handleFileDisplay} />
          </Grid>
          {document && document.files && (
            document.files.map((file, index) => (<Grid item className={classes.displayFile} lg={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {typeof file === "string" || file.type === fileFormatsTypes.PDF[0] ? (
                  <Pdf style={{ marginRight: 8 }} />
                ) : (
                  <img
                    src={img}
                    alt="doc"
                    style={{ height: 35, marginRight: 8 }}
                  />
                )}
                <Typography>{typeof file === "string" ? file : file.name}</Typography>
              </div>
              <div>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={() => this.onDeleteFile(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>))
          )}
          <Notes comments={document?.comments || []} comment={this.state.document.comment} handleChange={this.handleChange} />
        </Grid>
      </Popup>
    );
  }
}

export default withStyles(styles)(DocPopup);
