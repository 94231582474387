import { call, put, takeLatest } from "redux-saga/effects";
import { apiEndPoints } from "../../config/end-points";
import request from "../../utils/request";
import { requestDocuments } from "../Dashboard/action";
import { defaultFilterCriteria } from "../Dashboard/FilterConfig";
import { addToast } from "../Toast/actions";
import { STATUS_ERROR, STATUS_SUCCESS } from "../Toast/constants";
import {
  setDocumentFields,
  setDocumentTypes,
  setFileName,
  setServices,
  setUsers
} from "./action";
import {
  ADD_ATTACHMENT,
  ADD_DOCUMENT,
  REQUEST_DOCUMENT_FIELDS,
  REQUEST_DOCUMENT_TYPES,
  REQUEST_SERVICES,
  REQUEST_USERS
} from "./constants";

export function* requestServices(action) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const services = yield call(request, apiEndPoints.services, options);
    if (services && services.length !== 0) {
      yield put(setServices(services));
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestServicesSaga() {
  yield takeLatest(REQUEST_SERVICES, requestServices);
}

export function* requestUsers(action) {
  const options = {
    method: "GET",
    headers: {
      // 'Content-Type': 'application/json',
    },
  };
  try {
    const users = yield call(
      request,
      apiEndPoints.users(action.pageConfig),
      options
    );
    if (users && users.length !== 0) {
      yield put(setUsers(users));
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestUsersSaga() {
  yield takeLatest(REQUEST_USERS, requestUsers);
}

export function* requestDocumentTypes(action) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const documentTypes = yield call(
      request,
      apiEndPoints.documentTypes(),
      options
    );
    if (documentTypes && documentTypes.length !== 0) {
      yield put(setDocumentTypes(documentTypes));
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestDocumentTypesSaga() {
  yield takeLatest(REQUEST_DOCUMENT_TYPES, requestDocumentTypes);
}

export function* requestDocumentFields(action) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    if (action.id == null) {
      yield put(setDocumentFields([]));
    } else {
      const documentFields = yield call(
        request,
        apiEndPoints.documentFields(action.id),
        options
      );
      if (documentFields) {
        yield put(setDocumentFields(documentFields));
      }
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestDocumentFieldsSaga() {
  yield takeLatest(REQUEST_DOCUMENT_FIELDS, requestDocumentFields);
}

export function* requestAddAttachment(action) {
  let formData = new FormData();

  formData.append("file", action.file);

  const options = {
    method: "POST",
    headers: {
      // 'Content-Type': 'application/json',
    },
    body: formData,
  };

  try {
    const filename = yield call(request, apiEndPoints.addAttachment, options);
    if (filename) {
      yield put(setFileName(filename));
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestAddAttachmentSaga() {
  yield takeLatest(ADD_ATTACHMENT, requestAddAttachment);
}

export function* requestAddDocument(action) {
  const files = action.document.files;

  const document = action.document;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    let response;
    const notUploaded = files.filter(f => typeof f !== "string");
    if ( files.length && notUploaded.length) {
      let formData = new FormData();
      if(notUploaded.length)
      notUploaded.forEach(item => {
        formData.append('file', item);
      });
      const AttachmentOptions = {
        method: "POST",
        body: formData,
      };

      response = yield call(
        request,
        apiEndPoints.addAttachments,
        AttachmentOptions
      );
    }

    delete document.department;
    options.body = JSON.stringify({
      ...document,
      link: response && response.length
      ? response.map(f => f.fileName).join(';') +";"+ files.filter(f => typeof f === "string").join(';')
      : files.join(';'),
    });
    yield call(request, apiEndPoints.addDocument, options);
    yield put(requestDocuments(defaultFilterCriteria, { page: 0, size: 10 }));
    yield put(
      addToast({
        status: STATUS_SUCCESS,
        title: "SUCCESS",
        message: "Votre opération a été exécutée avec succès",
      })
    );
    action.close();
    if (document.id && document.comment) {
      const options2 = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ documentId: document.id, message: document.comment}) }
      yield call(request, apiEndPoints.addComment, options2);
    }
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: error?.response?.statusLabel
          ? error?.response?.statusLabel
          : "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestAddDocumentsSaga() {
  yield takeLatest(ADD_DOCUMENT, requestAddDocument);
}

export default [
  requestServicesSaga,
  requestUsersSaga,
  requestDocumentTypesSaga,
  requestDocumentFieldsSaga,
  requestAddAttachmentSaga,
  requestAddDocumentsSaga,
];
