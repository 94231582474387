import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  searchInput: {
    marginLeft: 8,
  },
  paper: {
    borderRadius: 8,
    border: "1px solid rgba(42, 105, 188, 0.12)",
    margin: "14px 24px 24px 24px",
    padding: "10px 0",
    background: "#FFFFFF",
    height: 495,
    overflow: "hidden",
  },
  container: {
    height: 535,
    overflow: "hidden",
    marginBottom: 15,
  },
  affectClass: {
    color: "#000000",
    opacity: 0.5,
  },
  customClass: {
    fontSize: 14,
    fontFamily: "SFProDisplayBold",
  },
  containerClass: {
    maxHeight: 450,
  },
  desaffected: {
    marginLeft: 24,
    marginTop: 14,
    textTransform: "none",
  },
}));

export default useStyles;
