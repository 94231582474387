import {fromJS} from "immutable";
import {INIT_DOCUMENTS_PAGE, NEXT_DOCUMENTS_PAGE, SET_COUNT, SET_DOCUMENTS} from "./constants";

export const initialState = fromJS({
    documentsList: [],
    count: 0,
    documentsListPage: 1,
});

function documentsReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_DOCUMENTS_PAGE:
            return state
                .set('documentsListPage', 1)
                .set('documentsList', fromJS([]));
        case SET_DOCUMENTS:
            return action.node
                ? state.set(action.node, fromJS(action.documentsList))
                : state.set(
                    'documentsList',
                    fromJS(action.documentsList),
                );
        case NEXT_DOCUMENTS_PAGE:
            return state.set('documentsListPage', state.get('documentsListPage') + 1);
        case SET_COUNT:
            return state.set('count', fromJS(action.count));
        default:
            return state;
    }
}

export default documentsReducer;