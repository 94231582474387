import {
  Box,
  Checkbox,
  FormControl, InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ConfirmDialog from "../../components/ConfirmDialog";
import DataTable from "../../components/DataTable";
import DocumentActions from "../../components/DocumentActions";
import DocumentHistory from "../../components/DocumentHistory";
import Filter from "../../components/Filter";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import DocumentViewPage from "../DocumentViewPage";
import PopupContainer from "../PopupContainer";
import {
  requestDocumentTypes,
  requestServices,
  requestUsers
} from "../PopupContainer/action";
import {
  makeSelectDocumentFields,
  makeSelectDocumentTypes,
  makeSelectServices,
  makeSelectUsers
} from "../PopupContainer/selector";
import {
  initDocumentsPage,
  requestDeleteDocument,
  requestDocuments
} from "./action";
import { COLUMNS } from "./constants";
import { filterCriteria, STATUS, YEARS } from "./FilterConfig";
import { makeSelectDocumentsCount, makeSelectDocumentsList } from "./selector";

const styles = {
  root: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    height: "100%",
  },
  body: {
    display: "flex",
    direction: "row",
    height: "100%",
    overflowY: "scroll",
    padding: 25,
  },
};

const ColumnIds = COLUMNS.map(c => c.id);
class Dashboard extends Component {
  state = {
    open: false,
    openDetail: false,
    openHistory: false,
    openDelete: false,
    columnDetail: ColumnIds,
    selectedDocument: {
      documentTypeId: null,
      amount: null,
      reference: null,
      department: null,
      validators: [],
      fields: {},
      file: null,
      link: null,
    },
    documentsList: [],
    selectedValidators: [],
    pageConfig: {
      page: 0,
      size: 20,
    },
    searchCriteria: {},
  };

  componentWillMount() {
    this.props.initDocumentsPage();
    this.props.requestDocuments(
      this.state.searchCriteria,
      this.state.pageConfig
    );
    this.props.requestServices();
    this.props.requestUsers();
    this.props.requestDocumentTypes();
  }

  componentDidMount() {
    this.props.requestDocuments(
      this.state.searchCriteria,
      this.state.pageConfig
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.documentsList &&
      this.props.documentsList !== prevProps.documentsList
    ) {
      this.setState({
        documentsList: this.props.documentsList.map((doc) => ({
          ...doc,
          id: doc.id,
          date: doc.date,
          department: doc.documentType.department.name,
          addedBy: doc.user.firstName + " " + doc.user.lastName,
          validators: doc.validators,
          status: doc.status,
          fields: doc.fields,
        })),
      });
    }
  }

  onAddFileClick = (document) => {
    this.setState({ open: true });
    const selectedDocument = this.initDocument(document, false);
    this.setState({
      selectedDocument: selectedDocument,
      selectedValidators: document ? document.validators : [],
    });
  };

  onViewClick = (document) => {
    this.setState({ openDetail: true });
    const selectedDocument = this.initDocument(document, true);
    this.setState({
      selectedDocument: selectedDocument,
      selectedValidators: document ? document.validators : [],
    });
  };

  onHistoryClick = (document) => {
    this.setState({ openHistory: true });
    const selectedDocument = this.initDocument(document, true);
    this.setState({
      selectedDocument: selectedDocument,
      selectedValidators: document ? document.validators : [],
    });
  };

  onDeleteClick = (document) => {
    this.setState({ openDelete: true });
    const selectedDocument = this.initDocument(document, true);
    this.setState({
      selectedDocument: selectedDocument,
      selectedValidators: document ? document.validators : [],
    });
  };

  setSelectedFields = (fields) => {
    let selectedFields = {};
    fields &&
      fields.length !== 0 &&
      fields.map((field) => {
          selectedFields[field.name] = field.value;
      });
    return selectedFields;
  };

  initDocument = (selectedDocument, view) => {
    if (selectedDocument) {
      const fields = this.setSelectedFields(selectedDocument.fields);
      return {
        id: selectedDocument.id,
        documentTypeId: selectedDocument.documentType.id,
        amount: selectedDocument.amount,
        reference: selectedDocument.reference,
        department: selectedDocument.documentType.department.id,
        comments: selectedDocument.comments,
        validators: !view
          ? selectedDocument.validators
            ? selectedDocument.validators.map((elt) => elt.id)
            : []
          : selectedDocument.validators,
        fields: fields,
        files: selectedDocument.links,
        links: selectedDocument.links,
        urls: selectedDocument.urls,
        service: selectedDocument.documentType?.department?.name,
        documentType: selectedDocument?.documentType?.name,
        timeline: selectedDocument.timeline,
      };
    }
  };

  handleClose = (prop) => () => {
    this.setState({ [prop]: false });
    this.setState({
      selectedDocument: {
        documentTypeId: null,
        amount: null,
        reference: null,
        department: null,
        validators: [],
        fields: {},
        file: null,
        link: null,
      },
    });
  };

  handleFilterConfigChange = (searchCriteria) => {
    this.setState({
      searchCriteria: searchCriteria,
      columnDetail:
        !searchCriteria.documentType ||
          Object.keys(searchCriteria.documentType).length === 0
          ? COLUMNS.map(c => c.id)
          : this.state.columnDetail,
      pageConfig: {
        size: this.state.pageConfig.size,
        page: 0
      }
    });
  };

  handlePageChange = (pageConfig) => {
    this.setState({ pageConfig: pageConfig });
    this.props.requestDocuments(this.state.searchCriteria, pageConfig);
  };

  handleSearchDocuments = (reset) => {
    if (reset) {
      this.props.requestDocuments({}, this.state.pageConfig);
    } else {
      const additionalFields = this.state.searchCriteria.additionalFields;
      const search = this.state.searchCriteria.search;
      this.props.requestDocuments(
        {
          ...this.state.searchCriteria,
          additionalFields:
            additionalFields &&
              Object.keys(additionalFields).length === 0 &&
              Object.getPrototypeOf(additionalFields) === Object.prototype
              ? undefined
              : additionalFields,
          search: search ? search : undefined,
        },
        this.state.pageConfig
      );
    }
  };

  handleChangeColumns = (event) => {
    this.setState({ columnDetail: event.target.value });
  };

  handleRefresh = () => {
    this.handleSearchDocuments();
    this.handleClose('openDelete')();
  }
  handleDeleteDocument = () => {
    this.props.requestDeleteDocument(this.state.selectedDocument?.id, this.handleRefresh);
  }
  render() {
    const { classes, services, users, documentTypes } = this.props;
    const { searchCriteria } = this.state;
    const additionalFields = searchCriteria.documentType
      ? documentTypes.find((doc) => doc.id === searchCriteria.documentType)
        ?.additionalFields
      : [];
    const finalColumns = this.state.columnDetail.map((c) => {
      const found = COLUMNS.find(cl => cl.id === c);
      return found
        ? found
        : {
          id: c,
          label: c,
          render: ({ row }) => {
            const field = row.fields?.find((f) => f.name === c);
            if (field?.type?.type === "DATE") {
              const [year, month, day] = row.fields?.find((f) => f.name === c)?.value?.split('-');
              return year && month && day && format(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)), 'dd/MM/yyyy')
            }
            return field && field?.value;
          },
          order: true
        }
    });
    const columns = [...ColumnIds, ...additionalFields.map(a => a.name)];
    return (
      <div className={classes.root}>
        <Sidebar />
        <Grid container className={classes.body}>
          <Box style={{ width: "100%" }}>
            <Filter
              services={services}
              users={users}
              documentTypes={documentTypes}
              date={YEARS}
              status={STATUS}
              handleSearch={this.handleSearchDocuments}
              handleFilterChange={this.handleFilterConfigChange}
              filterCriteria={filterCriteria}
            />
            <Box style={{ display: "flex", margin: '10px 0' }}>
              <Box style={{ flexGrow: 1 }}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: 230 }}
                >
                  <InputLabel>Champs additionnels</InputLabel>
                  <Select
                    autoWidth
                    multiple
                    renderValue={(selected) => selected.join(", ")}
                    label="Champs additionnels"
                    defaultValue={[]}
                    onChange={this.handleChangeColumns}
                    value={this.state.columnDetail}
                  >
                    {columns.map((a) => (
                      <MenuItem value={a} key={a}>
                        <Checkbox
                          color="primary"
                          checked={
                            this.state.columnDetail.indexOf(a) > -1
                          }
                        />
                        <ListItemText primary={a} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Header
                buttonLabel="Ajouter un document"
                onCallToAction={this.onAddFileClick}
              />
            </Box>
          </Box>
          <DataTable
            documents={this.state.documentsList}
            columns={finalColumns}
            handlePageChange={this.handlePageChange}
            totalDocuments={this.props.totalDocuments}
            page={this.state.pageConfig.page}
            actions={(document) => (
              <DocumentActions
                onAddFileClick={this.onAddFileClick}
                onViewClick={this.onViewClick}
                onHistoryClick={this.onHistoryClick}
                onDeleteDocument={this.onDeleteClick}
                requestDocuments={this.props.requestDocuments}
                document={document}
              />
            )}
          />
        </Grid>
        {this.state.open && (
          <PopupContainer
            open={this.state.open}
            handleClose={this.handleClose("open")}
            selectedDocument={this.state.selectedDocument}
            selectedValidators={this.state.selectedValidators}
          />
        )}
        {this.state.openDetail && (
          <DocumentViewPage
            open={this.state.openDetail}
            handleClose={this.handleClose("openDetail")}
            selectedDocument={this.state.selectedDocument}
          />
        )}
        {this.state.openHistory && (
          <DocumentHistory
            open={this.state.openHistory}
            handleClose={this.handleClose("openHistory")}
            selectedDocument={this.state.selectedDocument}
          />
        )}
        {this.state.openDelete && (
          <ConfirmDialog
            title="Suppression"
            content={"Voulez vous vraiment supprimer ce Document (id: " + this.state.selectedDocument?.id + ") ?"}
            handleClose={this.handleClose("openDelete")}
            handleClick={this.handleDeleteDocument}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  requestDocuments,
  initDocumentsPage,
  requestDeleteDocument,
  requestDocumentTypes,
  requestUsers,
  requestServices,
};

const mapStateToProps = createStructuredSelector({
  documentsList: makeSelectDocumentsList(),
  totalDocuments: makeSelectDocumentsCount(),
  services: makeSelectServices(),
  users: makeSelectUsers(),
  documentTypes: makeSelectDocumentTypes(),
  secondaryFields: makeSelectDocumentFields(),
});

Dashboard.propTypes = {
  requestDocuments: PropTypes.func,
  requestDeleteDocument: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard));
