export const fileFormatsTypes = {
    PDF: ['application/pdf'],
    IMG: [
        'image/jpeg',
        'image/png',
    ],
};

export const acceptedFileTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
];