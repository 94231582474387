import { call, put, takeLatest } from "redux-saga/effects";
import history from "../../utils/history";
import { loginFailed, loginSuccess } from "./actions";
import { LOGIN_REQUESTED } from "./constants";
import { logIn } from "../../utils/auth_util";
import { endPoints } from "../../config/end-points";

export function* login() {
  try {
    const response = yield call(logIn);

    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loginFailed(error.message));
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUESTED, login);
}

export default [loginSaga];
