export const REQUEST_SERVICES = 'REQUEST_SERVICES';
export const REQUEST_USERS = 'REQUEST_USERS';
export const REQUEST_DOCUMENT_TYPES = 'REQUEST_DOCUMENT_TYPES';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_USERS = 'SET_USERS';
export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const REQUEST_DOCUMENT_FIELDS = 'REQUEST_DOCUMENT_FIELDS';
export const SET_DOCUMENT_FIELDS = 'SET_DOCUMENT_FIELDS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const SET_FILENAME = 'SET_FILENAME';