import React, {Component} from "react";
import {
    addAttachment,
    addDocument,
    requestDocumentFields,
    requestDocumentTypes,
    requestServices,
    requestUsers
} from "./action";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import DocPopup from "../../components/Popup/DocPopup";
import {makeSelectDocumentFields, makeSelectDocumentTypes, makeSelectServices, makeSelectUsers} from "./selector";

class PopupContainer extends Component {

    componentDidMount(){
        if(this.props.services && this.props.services.length === 0){
            this.props.requestServices();
        }
        if(this.props.users && this.props.users.length === 0){
            this.props.requestUsers();
        }
        if(this.props.documentTypes && this.props.documentTypes.length === 0){
            this.props.requestDocumentTypes();
        }
    }

    render(){

        const {services, users, documentTypes, secondaryFields, selectedDocument, selectedValidators} = this.props;

        return (
            <DocPopup
                open={this.props.open}
                handleClose={this.props.handleClose}
                selectedDocument={selectedDocument}
                selectedValidators={selectedValidators}
                services={services}
                users={users}
                documentTypes={documentTypes}
                handleDocumentTypeChange={this.props.requestDocumentFields}
                secondaryFields={secondaryFields}
                requestAddAttachment={this.props.addAttachment}
                requestAddDocument={this.props.addDocument}
            />
        );
    }
}

const mapDispatchToProps = {
    requestDocumentTypes,
    requestUsers,
    requestServices,
    requestDocumentFields,
    addAttachment,
    addDocument,
};

const mapStateToProps = createStructuredSelector({
    services: makeSelectServices(),
    users: makeSelectUsers(),
    documentTypes: makeSelectDocumentTypes(),
    secondaryFields: makeSelectDocumentFields(),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupContainer);