const PRIMITIVE_TYPES = [
    'INT',
    'DECIMAL',
    'STRING',
    'PHONE',
    'EMAIL',
    'BOOLEAN',
    'DATE',
    'CLIENT',
];

export default PRIMITIVE_TYPES;