import React, { useCallback, useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import EnhancedTableStatic from "../EnhancedTableStatic";
import useStyles from "./style";
import { headCells, initFilters } from "./constant";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

export default function DocumentHistory({
  open,
  handleClose,
  selectedDocument: { timeline = [] },
}) {
  const classes = useStyles();

  const [values, setValues] = React.useState(initFilters);
  const handleChangeInput = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const list = timeline.map((t) => ({
    ...t,
    userName: t.user?.firstName + " " + t.user?.lastName,
    date: t.createdAt,
  })).filter((event) => (values.search === '' ? true : event.userName?.toLowerCase().includes(values.search.toLowerCase())));
  return (
    <Dialog onClose={handleClose} maxWidth="lg" open={open} fullWidth>
      <DialogTitle onClose={handleClose}>
        Historique des modifications
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.container}>
          <Paper elevation={0} classes={{ root: classes.paper }}>
            <FormControl
              margin="dense"
              variant="outlined"
              className={classes.searchInput}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Utilisateur
              </InputLabel>
              <OutlinedInput
                style={{ width: 360 }}
                type="text"
                value={values.search}
                onChange={handleChangeInput("search")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" size="medium">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            {open && (
              <EnhancedTableStatic
                defaultRowsPerPage={list.length}
                containerClass={classes.containerClass}
                rows={list}
                headCells={headCells}
                count={list.length}
                pagination={false}
              />
            )}
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  );
}
