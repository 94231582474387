import {
        DELETE_DOCUMENT,
        INIT_DOCUMENTS_PAGE,
        NEXT_DOCUMENTS_PAGE,
        REQUEST_DOCUMENTS, SET_COUNT,
        SET_DOCUMENTS,
} from "./constants";

export const requestDocuments = (filterCriteria, searchCriteria, node) => ({
        type: REQUEST_DOCUMENTS,
        filterCriteria,
        searchCriteria,
        node,
});

export const setDocuments = (documentsList, node) => ({
        type: SET_DOCUMENTS,
        documentsList,
        node,
});

export const initDocumentsPage = () => ({
        type: INIT_DOCUMENTS_PAGE,
});


export const nextDocumentsPage = () => ({
        type: NEXT_DOCUMENTS_PAGE,
});

export const requestDeleteDocument = (id, refresh) => ({
        type: DELETE_DOCUMENT,
        id,
        refresh
});

export const setDocumentsCount = (count) => ({
        type: SET_COUNT,
        count
});