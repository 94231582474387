import { ADD_TOAST, REMOVE_TOAST, SET_TOAST } from './constants';

export function addToast(options) {
  return {
    type: ADD_TOAST,
    options,
  };
}

export function setToast(toast) {
  return {
    type: SET_TOAST,
    toast,
  };
}

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    id,
  };
}
