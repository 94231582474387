import React, { Component } from "react";
import MoreHorizButton from "../../common/MoreHorizButton";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {ReactComponent as Visibility} from "../../images/icons/visibilityAction.svg";


function ValidationActions(props) {

    const {document} = props;

    const handleValidation = (document) => {
        const decision = {
            documentId: document.id,
            action: 'APPROVE',
        };
        props.onValidateDocumentClick(decision);
    };

    const handleRefusal = (document) => {
        const decision = {
            documentId: document.id,
            action: 'DECLINE',
        };
        props.onValidateDocumentClick(decision);
    };

    const handleViewDocument = (link) => {
        window.open(link, "_blank");
    };

    return (
            <MoreHorizButton
                items={[
                    {
                        key: 1,
                        value: "Visualiser",
                        onClick: () => handleViewDocument(document.url),
                        icon: <Visibility style={{color: '#10277C'}}/>,
                    },
                    {
                        key: 2,
                        value: "Approuver",
                        onClick: () => {handleValidation(document)},
                        icon: <CheckIcon style={{color: '#10277C'}}/>,
                    },
                    {
                        key: 3,
                        value: "Refuser",
                        onClick: () => {handleRefusal(document)},
                        icon: <CloseIcon style={{color: '#10277C'}}/>,
                    },
                ]}
            />
        );
}

export default ValidationActions;
