import request from "../../utils/request";
import {apiEndPoints} from "../../config/end-points";
import {addToast} from "../Toast/actions";
import {STATUS_ERROR, STATUS_SUCCESS} from "../Toast/constants";
import { call, put, takeLatest } from 'redux-saga/effects';
import {ADD_FIELD, REQUEST_FIELD_TYPES} from "./constant";
import {setFieldTypes} from "./action";


export function* requestFieldTypes(action) {
    const options = {
        method: 'GET',
        headers: {
            //'Content-Type': 'application/json',
        },
    };
    try {
        const fieldTypes = yield call(request, apiEndPoints.types, options);
        if (fieldTypes && fieldTypes.length !== 0) {
            yield put(setFieldTypes(fieldTypes));
        }
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestFieldTypesSaga() {
    yield takeLatest(REQUEST_FIELD_TYPES, requestFieldTypes);
}

export function* requestAddField(action) {

    const field = action.field;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(field),
    };


    try {
        yield call(request, apiEndPoints.addField(field.id), options);
        yield put(
            addToast({
                status: STATUS_SUCCESS,
                title: 'SUCCESS',
                message: 'Votre opération a été exécutée avec succès',
            }),
        );
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestAddFieldSaga() {
    yield takeLatest(ADD_FIELD, requestAddField);
}


export default [
    requestFieldTypesSaga,
    requestAddFieldSaga
]