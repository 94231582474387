export const endPoints = {
  me: "/users/me",
  dashboard: "/home",
  validation: "/validation",
  addUser: "addUser",
  addDocumentType: "documentType",
  addDepartment: "service",
  departments: "services",
  addFieldType: "/addType",
};

export const apiEndPoints = {
  login: "/login",
  documents: (pageConfig) =>
    `/documents?page=${pageConfig.page}&size=${pageConfig.size}${
      pageConfig.sort ? `&sort=${pageConfig.sort}` : ""
    }`,
  services: "/departments",
  documentTypes: (pageConfig) =>
    pageConfig
      ? `/documents/types?page=${pageConfig.page}&size=${pageConfig.size}${
          pageConfig.sort ? `&sort=${pageConfig.sort}` : ""
        }`
      : "/documents/types",
  users: (pageConfig) =>
    pageConfig
      ? `/users?page=${pageConfig.page}&size=${pageConfig.size}${
          pageConfig.sort ? `&sort=${pageConfig.sort}` : ""
        }`
      : "/users",
  documentFields: (id) => `/config/document/${id}/fields`,
  addAttachment: "/attachments/images/add",
  addAttachments: "/attachments/images-list/add",
  addDocument: "/documents/add",
  addComment: "/documents/comments/add",
  deleteDocument: (id) => `/documents/delete/${id}`,
  deleteDocumentType: (id) => `/documents/types/delete/${id}`,
  deleteDepartment: (id) => `/departments/delete/${id}`,
  deleteFieldType: (id) => `/config/types/delete/${id}`,
  validation: "/documents/validate",
  addUser: "/users/add",
  deleteUser: (user) => "/users/delete/" + user.id,
  addDepartment: "/departments/add",
  addField: (id) => `/config/document/${id}/fields/add`,
  types: "/config/types",
  addType: "/config/types/add",
  editListValue: "/config/types/value/modify",
  documentsToValidate: (filterCriteria, pageConfig) =>
    `/documents/tovalidate?page=${pageConfig.page}size=${pageConfig.size}${
      pageConfig.sort ? `&sort=${pageConfig.sort}` : ""
    }${filterCriteria.date ? "&year=" + filterCriteria.date : ""}${
      filterCriteria.service ? "&service=" + filterCriteria.service : ""
    }${
      filterCriteria.document_type
        ? "&document_type=" + filterCriteria.document_type
        : ""
    }${
      filterCriteria.responsable
        ? "&responsable=" + filterCriteria.responsable
        : ""
    }${filterCriteria.search ? "&search=" + filterCriteria.search : ""}`,
  addDocumentType: "/documents/types/add",
};
