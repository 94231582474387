import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import {ReactComponent as UploadCloud} from "../../images/icons/uploadCloud.svg";
import Typography from '@material-ui/core/Typography/Typography';
import {acceptedFileTypes} from "../../containers/App/constants";


const styles = () => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    column: {
        width: '80%',
    },
    fileImage: {
        height: 20,
        width: 30,
    },
    dropZone: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    uploadImg: {
        margin: 20,
        width: 50,
    },
    contentText: {
        fontFamily: 'Noto Sans',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    contentBox: {
        outline: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentTextBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
});

class DropZone extends Component {
    onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(f => {
            const file = new FormData();
            file.append('file', f, f.name);
        });
        this.props.onFileDrop(acceptedFiles);
    };

    render() {
        const { classes, acceptedTypes } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.column}>
                    <ReactDropzone
                        onDrop={this.onDrop}
                        multiple={false}
                        accept={acceptedTypes || acceptedFileTypes}
                    >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <Fragment>
                                <div className={classes.dropZone} id="dropzone">
                                    <div
                                        {...getRootProps()}
                                        className={classNames('dropzone', classes.contentBox, {
                                            'dropzone--isActive': isDragActive,
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <div className={classes.dropZone}>
                                            <UploadCloud style={{margin: 16}}/>
                                            <div className={classes.contentTextBox}>
                                                {isDragActive ? (
                                                    <Typography>
                                                        Glissez le fichier ici...
                                                    </Typography>
                                                ) : (
                                                    <Fragment>
                                                        <Typography>
                                                            Faites glisser et déposez vos fichiers ou
                                                            parcourez vos documents pour choisir un fichier.
                                                        </Typography>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </ReactDropzone>
                </div>
            </div>
        );
    }
}


export default (withStyles(styles)(DropZone));
