import React from "react";
import { Chip } from "@material-ui/core";

export const REQUEST_DOCUMENTS = "REQUEST_DOCUMENTS";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const INIT_DOCUMENTS_PAGE = "INIT_DOCUMENTS_PAGE";
export const NEXT_DOCUMENTS_PAGE = "NEXT_DOCUMENTS_PAGE";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SET_COUNT = "SET_COUNT";
const COLORS = {
  Approuvé: "#CDFFCD",
  "En cours de validation": "#FFECCC",
  Refusé: "#FFE0E0",
};
export const COLUMNS = [
  { id: "id", label: "ID de pièce", render: ({ value }) => value, order: true },
  { id: "date", label: "Date d'ajout", render: ({ value }) => value, order: true },
  { id: "department", label: "Service", render: ({ value }) => value, order: true },
  {
    id: "documentType",
    label: "Type de document",
    render: ({ row }) => row.documentType.name,
    order: true
  },
  { id: "addedBy", label: "Ajouté par", render: ({ value }) => value, order: true },
  {
    id: "validators",
    label: "Validateurs",
    render: ({ value }) =>
      value?.map((user) => (
        <li style={{ listStyleType: "none", margin: 2 }}>
          <Chip label={user.firstName + " " + user.lastName} />
        </li>
      ))
  },
  {
    id: "status",
    label: "Status",
    render: ({ value }) => (
      <Chip style={{ background: COLORS[value] }} label={value} />
    ),
    order: true
  },
];
