import request from "../../utils/request";
import { apiEndPoints } from "../../config/end-points";
import { addToast } from "../Toast/actions";
import { STATUS_ERROR, STATUS_SUCCESS } from "../Toast/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_FIELD_TYPE, DELETE_FIELD_TYPE, EDIT_LIST_VALUE } from "./constant";
import { requestFieldTypes } from "../AddFieldPage/action";

export function* requestAddFieldType(action) {
  let formData = new FormData();

  const { name, type, file, values } = action.fieldType;

  formData.append("name", name);
  formData.append("type", type);
  formData.append("file", file);
  for (var i = 0; i < values.length; i++) {
    formData.append('values', values[i]);
  }

  const options = {
    method: "PUT",
    headers: {
      // 'Content-Type': 'application/json',
    },
    body: formData,
  };

  try {
    yield call(request, apiEndPoints.addType, options);
    yield put(requestFieldTypes());
    yield put(
      addToast({
        status: STATUS_SUCCESS,
        title: "SUCCESS",
        message: "Votre opération a été exécutée avec succès",
      })
    );
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestAddFieldTypeSaga() {
  yield takeLatest(ADD_FIELD_TYPE, requestAddFieldType);
}

export function* requestEditValueListType(action) {
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(action.value),
  };

  try {
    yield call(request, apiEndPoints.editListValue, options);
    yield put(requestFieldTypes());
    yield put(
      addToast({
        status: STATUS_SUCCESS,
        title: "SUCCESS",
        message: "Votre opération a été exécutée avec succès",
      })
    );
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestEditValueListTypeSaga() {
  yield takeLatest(EDIT_LIST_VALUE, requestEditValueListType);
}
export function* requestDeleteFieldType(action) {
  const { id } = action;

  const options = {
    method: "DELETE",
    headers: {},
    body: JSON.stringify(id),
  };

  try {
    yield call(request, apiEndPoints.deleteFieldType(id), options);
    yield put(requestFieldTypes());
    yield put(
      addToast({
        status: STATUS_SUCCESS,
        title: "SUCCESS",
        message: "Votre opération a été exécutée avec succès",
      })
    );
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestDeleteFieldTypeSaga() {
  yield takeLatest(DELETE_FIELD_TYPE, requestDeleteFieldType);
}

export default [
  requestAddFieldTypeSaga,
  requestDeleteFieldTypeSaga,
  requestEditValueListTypeSaga,
];
