import { fromJS } from "immutable";
import {
  SET_DOCUMENT_FIELDS,
  SET_DOCUMENT_TYPES,
  SET_FILENAME,
  SET_SERVICES,
  SET_USERS,
} from "./constants";

export const initialState = fromJS({
  documentTypes: [],
  services: [],
  users: [],
  documentFields: [],
  filename: "",
});

function popupContainerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT_TYPES:
      return state.set("documentTypes", fromJS(action.documentTypes));
    case SET_SERVICES:
      return state.set("services", fromJS(action.services));
    case SET_USERS:
      return state.set("users", fromJS(action.users));
    case SET_DOCUMENT_FIELDS:
      return state.set("documentFields", fromJS(action.documentFields));
    case SET_FILENAME:
      return state.set("filename", fromJS(action.filename));
    default:
      return state;
  }
}

export default popupContainerReducer;
