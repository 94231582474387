export function get(type) {
    return JSON.parse(window.localStorage.getItem(type));
}

export function set(type, data) {
    window.localStorage.setItem(type, JSON.stringify(data));
}

export function clear(type) {
    window.localStorage.removeItem(type);
}

export function clearAll() {
    window.localStorage.clear();
}
