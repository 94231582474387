import React from "react";
import {ReactComponent as EditIcon} from '../../images/icons/edit.svg';
import {ReactComponent as DeleteIcon} from '../../images/icons/trash.svg';
import MoreHorizButton from "../../common/MoreHorizButton";
import history from "../../utils/history";
import {endPoints} from "../../config/end-points";

function DocumentTypeActions(props) {
    const {documentType} = props;

    const handleUpdate= (documentType) => {
        history.push({
            pathname: endPoints.addDocumentType,
            state: {
                documentType: documentType,
            },
        });
    };

    return (
        <MoreHorizButton
            items={[
                {
                    key: 1,
                    value: "Modifier",
                    onClick: () => {handleUpdate(documentType)},
                    icon: <EditIcon style={{color: '#10277C'}}/>,
                },
                {
                    key: 2,
                    value: "Supprimer",
                    onClick: () => {
                        props.requestDeleteDocumentType(documentType.id);
                        props.requestDocumentTypes();
                    },
                    icon: <DeleteIcon style={{color: '#10277C'}}/>,
                },
            ]}
        />
    );

}

export default DocumentTypeActions;
