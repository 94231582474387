import {ADD_FIELD, REQUEST_FIELD_TYPES, SET_FIELD_TYPES} from "./constant";

export const requestAddField = (field, node) => ({
    type: ADD_FIELD,
    field,
    node
});

export const requestFieldTypes = () => ({
    type: REQUEST_FIELD_TYPES,
});

export const setFieldTypes = (fieldTypes, node) => ({
    type: SET_FIELD_TYPES,
    fieldTypes,
    node
});