import loginSagas from './containers/Login/saga';
import dashboardSagas from './containers/Dashboard/saga';
import docPopupSagas from './containers/PopupContainer/saga';
import validationSagas from './containers/ValidationBoard/saga';
import addUserSagas from './containers/AddUserPage/saga';
import addDepartmentSagas from './containers/AddServicePage/saga';
import documentFieldsSaga from './containers/AddFieldPage/saga';
import addFieldTypeSaga from './containers/AddTypePage/saga';
import addDocumentTypeSaga from './containers/AddDocumentTypePage/saga';
import toastSaga from './containers/Toast/sagas';

const sagas = [
    ...toastSaga,
    ...loginSagas,
    ...dashboardSagas,
    ...docPopupSagas,
    ...validationSagas,
    ...addUserSagas,
    ...addDepartmentSagas,
    ...documentFieldsSaga,
    ...addFieldTypeSaga,
    ...addDocumentTypeSaga,
];

export default sagas;
