import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectDocuments = state => state.get('documents', initialState);

const selectDocumentsList = createSelector(
    selectDocuments,
    documentState =>
        documentState.get('documentsList')
            ? documentState
                .get('documentsList')
                .toJS()
            : [],
);


const selectSelectedDocument = createSelector(
    selectDocuments,
    documentState =>
        documentState.get('selectedExercise')
            ? documentState.get('selectedExercise').toJS()
            : null,
);

const selectDocumentsListPage = createSelector(
    selectDocuments,
    documentState =>
        documentState.get('documentsListPage') ? documentState.get('documentsListPage') : 0,
);

const selectDocumentsCount = createSelector(
    selectDocuments,
    documentState =>
        documentState.get('count') ? documentState.get('count') : 0,
);

export const makeSelectDocumentsListPage = () => selectDocumentsListPage;
export const makeSelectDocumentsList = () => selectDocumentsList;
export const makeSelectSelectedDocument = () => selectSelectedDocument;
export const makeSelectDocumentsCount = () => selectDocumentsCount;
