import {fromJS} from "immutable";
import {SET_FIELD_TYPES, SET_SERVICES} from "./constant";

export const initialState = fromJS({
    fieldTypes: [],
    services: [],
});

function addDocumentContainerReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FIELD_TYPES:
            return action.node
                ? state.set(action.node, fromJS(action.fieldTypes))
                : state.set('fieldTypes', fromJS(action.fieldTypes));
        case SET_SERVICES:
            return state.set("services", fromJS(action.services));
        default:
            return state;
    }
}

export default addDocumentContainerReducer;