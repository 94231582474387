import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import { get } from "../../services/appStorage";
import { USER_STORAGE } from "../../common/constant";

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typo: {
        fontFamily: 'Noto Sans',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        color: '#2A69BC',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveBtn: {
        color: "white !important",
        backgroundColor: "#10277C",
        width: "100%",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#C5DCFA87",
            boxShadow: "none",
        },
    },
};


class Header extends Component {
    render() {
        const { classes, onCallToAction, buttonLabel } = this.props;
        const user = get(USER_STORAGE);

        return (
            <Grid container direction="row-reverse">
                <Grid item>
                    <Button
                        onClick={() => onCallToAction()}
                        className={classes.approveBtn}
                    >
                        <AddIcon style={{ marginRight: 4 }} />
                        {buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Header);