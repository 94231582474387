/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import routeReducer from './routeReducer'
import loginReducer from '../containers/Login/reducer';
import documentsReducer from '../containers/Dashboard/reducer';
import { CLEAR_STORE } from '../common/constant';
import popupContainerReducer from "../containers/PopupContainer/reducer";
import documentFieldsReducer from '../containers/AddFieldPage/reducer';
import addDocumentContainerReducer from '../containers/AddNewDocumentTypePage/reducer';
import documentsToValidateReducer from "../containers/ValidationBoard/reducer";
import toastReducer from "../containers/Toast/reducer";

/**
 * Creates the main reducer with the dynamically injected ones
 */

export default function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    route: routeReducer,
    user: loginReducer,
    documents: documentsReducer,
    docPopup: popupContainerReducer,
    documentFields: documentFieldsReducer,
    documentsToValidate: documentsToValidateReducer,
    addDocumentType:addDocumentContainerReducer,
    toasts: toastReducer,
    ...injectedReducers,
  });

  return (state, action) => {
    if (action.type === CLEAR_STORE) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
}
