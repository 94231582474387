import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeSelectDocumentTypes } from "../PopupContainer/selector";
import { requestAddDocumentType, requestDeleteDocumentType } from "../AddDocumentTypePage/action";
import { requestDocumentTypes } from "../PopupContainer/action";
import DocumentView from "../../components/DocumentView";

class DocumentViewPage extends Component {

    render() {
        const { selectedDocument, open, handleClose } = this.props;

        return (
            <DocumentView open={open} handleClose={handleClose} selectedDocument={selectedDocument} />
        );
    }

}

const mapDispatchToProps = {
    requestDocumentTypes,
    requestDeleteDocumentType,
    requestAddDocumentType
};

const mapStateToProps = createStructuredSelector({
    documentTypesList: makeSelectDocumentTypes(),
});


DocumentViewPage.propTypes = {
    requestAddDocumentType: PropTypes.func,
    requestDocumentTypes: PropTypes.func,
    requestDeleteDocumentType: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentViewPage);