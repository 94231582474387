import { call, put, takeEvery } from 'redux-saga/effects';
import {delay} from 'redux-saga/effects'
import createToast from './createToast';
import { removeToast, setToast } from './actions';
import { ADD_TOAST, TIMEOUT_TOAST, toastType } from './constants';

export function* addToast(action) {
  try {
    const toast = yield call(createToast, action.options);
    yield put(setToast(toast));
    if (toast.type === toastType.SUCCESS) {
      yield delay(TIMEOUT_TOAST);
      yield put(removeToast(toast.id));
    }
  } catch (error) {
    console.error(error.message);
  }
}

export function* addToastSaga() {
  yield takeEvery(ADD_TOAST, addToast);
}

export default [addToastSaga];
