import React from "react";
import { Redirect, Route } from "react-router-dom";
import isAuthenticated from "../../services/security/isAuthenticated";
import { authUrl } from "../../utils/authConfig";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated()) {
        return <Component {...props} />;
      } else {
        window.location.href = authUrl;
        return null;
      }
    }}
  />
);

export default PrivateRoute;
