import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";
import {apiEndPoints} from "../../config/end-points";
import {addToast} from "../Toast/actions";
import {STATUS_ERROR, STATUS_SUCCESS} from "../Toast/constants";
import {ADD_DEPARTMENT, DELETE_DEPARTMENT} from "./constants";
import {requestServices} from "../PopupContainer/action";

export function* requestAddDepartment(action) {

    const department = action.department;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(department),
    };


    try {
        const response = yield call(request, apiEndPoints.addDepartment, options);
        yield put(
            addToast({
                status: STATUS_SUCCESS,
                title: 'SUCCESS',
                message: 'Votre opération a été exécutée avec succès',
            }),
        );

    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestAddDepartmentSaga() {
    yield takeLatest(ADD_DEPARTMENT, requestAddDepartment);
}

export function* requestDeleteDepartment(action) {

    const {id} = action;

    const options = {
        method: 'DELETE',
        headers: {
        },
        body: JSON.stringify(id),
    };


    try {
        yield call(request, apiEndPoints.deleteDepartment(id), options);
        yield put(requestServices());
        yield put(
            addToast({
                status: STATUS_SUCCESS,
                title: 'SUCCESS',
                message: 'Votre opération a été exécutée avec succès',
            }),
        );
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestDeleteDepartmentSaga() {
    yield takeLatest(DELETE_DEPARTMENT, requestDeleteDepartment);
}

export default [
    requestAddDepartmentSaga,
    requestDeleteDepartmentSaga
];