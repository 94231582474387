import {
  DONE_TEXT,
  ERROR_TEXT,
  STATUS_ERROR,
  STATUS_SUCCESS,
  STATUS_WARNING,
  toastType,
  WARNING_TEXT,
} from './constants';

let id = 0;

const colors ={
  SUPPORT_RED: '#f5523c',
  SUPPORT_ORANGE: '#f4b63e',
  SUPPORT_GREEN: '#32c284',
};

export default function createToast(options) {
  id += 1;
  let toast = {
    id,
    // TODO: modify title and text
    title: options.status,
    message: options.message,
    errors: options.errors,
  };
  switch (options.status) {
    case STATUS_SUCCESS: {
      toast = {
        ...toast,
        type: toastType.SUCCESS,
        //rectangleText: DONE_TEXT,
        style: {
          backgroundColor: colors.SUPPORT_GREEN,
        },
      };
      break;
    }
    case STATUS_ERROR: {
      toast = {
        ...toast,
        type: toastType.ERROR,
        rectangleText: ERROR_TEXT,
        style: {
          backgroundColor: colors.SUPPORT_RED,
        },
      };
      break;
    }
    case STATUS_WARNING: {
      toast = {
        ...toast,
        type: toastType.WARNING,
        rectangleText: WARNING_TEXT,
        style: {
          backgroundColor: colors.SUPPORT_ORANGE,
        },
      };
      break;
    }
    default:
      break;
  }
  return toast;
}
