import {initialState} from "./reducer";
import {createSelector} from "reselect";

const selectDocumentsToValidate = state => state.get('documentsToValidate', initialState);

const selectDocumentToValidateList = createSelector(
    selectDocumentsToValidate,
    documentsToValidateState =>
        documentsToValidateState.get('documentsToValidate')
            ? documentsToValidateState
                .get('documentsToValidate')
                .toJS()
            : [],
);

const selectDocumentsToValidateCount = createSelector(
    selectDocumentsToValidate,
    documentsToValidateState =>
        documentsToValidateState.get('count') ? documentsToValidateState.get('count') : 0,
);


export const makeSelectDocumentsToValidateList = () => selectDocumentToValidateList;
export const makeSelectDocumentsToValidateCount = () => selectDocumentsToValidateCount;
