import React, { useEffect } from "react";
import { connect } from "react-redux";
import { authUrl } from "../../utils/authConfig";
import { ACCESS_TOKEN_STORAGE } from "../../utils/request";
import { requestLogin } from "../Login/actions";
const Profile = ({ children, requestLogin: myProfile }) => {
  const receiveMessage = (e) => {
    if(e.origin === authUrl) {
      console.log(e.data);
      localStorage.setItem(ACCESS_TOKEN_STORAGE, e.data?.token);
      myProfile();
    }
  };
  useEffect(() => {
    window.addEventListener("message", receiveMessage);
  }, []);
  return <>{children}</>;
};

const mapDispatchToProps = {
  requestLogin,
};
export default connect(null, mapDispatchToProps)(Profile);
