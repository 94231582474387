import { createSelector } from 'reselect';

const selectUser = state => state.get('user');

const LoginFailed = createSelector(
    selectUser,
    login => (login.get('loginFailed') ? login.get('loginFailed') : false),
);

const makeSelectLoginFailed = () => LoginFailed;

export { makeSelectLoginFailed };
