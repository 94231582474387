import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStructuredSelector } from "reselect";
import { makeSelectServices, makeSelectFieldTypes } from "./selector";
import { connect } from "react-redux";
import {
  requestServices,
  requestAddDocumentType,
  requestFieldTypes,
} from "./action";
import AddNewDocumentType from "../../components/AddNewDocumentType";
import { addFieldType } from "../AddTypePage/action";
import { Box, Paper } from "@material-ui/core";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
};

class AddNewDocumentTypePage extends Component {
  componentDidMount() {
    if (this.props.services) {
      this.props.requestServices();
    }
    if (this.props.fieldTypes) {
      this.props.requestFieldTypes();
    }
  }

  render() {
    const { classes, services, fieldTypes } = this.props;
    const selectedDocumentType = this.props.location.state
      ? this.props.location.state.documentType
      : null;

    return (
      <div className={classes.root}>
        <Sidebar />
        <div style={{ height: "100%", overflowY: "auto" }}>
          <AddNewDocumentType
            className={classes.body}
            services={services}
            requestAddDocumentType={this.props.requestAddDocumentType}
            documentType={selectedDocumentType}
            fieldTypes={fieldTypes}
            addFieldType={this.props.addFieldType}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  requestServices,
  requestAddDocumentType,
  requestFieldTypes,
  addFieldType,
};

const mapStateToProps = createStructuredSelector({
  services: makeSelectServices(),
  fieldTypes: makeSelectFieldTypes(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddNewDocumentTypePage));
