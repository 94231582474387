import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      margin: theme.spacing(1),
    },
  },
  input: {
    backgroundColor: '#EDF1F2',
    fontSize: 16,
    borderRadius: 8,
    borderColor: '#EDF1F2',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      outline: 'none',
      borderRadius: 8,
      borderColor: '#EDF1F2',
    },
  },
}))(InputBase);

export default BootstrapInput;
