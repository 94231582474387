import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";
import {apiEndPoints} from "../../config/end-points";
import {addToast} from "../Toast/actions";
import {STATUS_ERROR} from "../Toast/constants";
import {REQUEST_DOCUMENTS_TO_VALIDATE, VALIDATE_DOCUMENT} from "./constant";
import {setDocumentsToValidate, setDocumentsToValidateCount, requestDocumentsToValidate as requestDocumentsToValidateAction} from "./action";
import {defaultFilterCriteria} from "../Dashboard/FilterConfig";

export function* requestDocumentValidation(action) {

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.decision)
    };

    try {
        yield call(request, apiEndPoints.validation, options);
        yield put(requestDocumentsToValidateAction(defaultFilterCriteria, {page: 0, size: 10}))
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestDocumentValidationSaga() {
    yield takeLatest(VALIDATE_DOCUMENT, requestDocumentValidation);
}

export function* requestDocumentsToValidate(action) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    try {
        const documents = yield call(request, apiEndPoints.documentsToValidate(action.filterCriteria, action.pageConfig), options);
        if (documents && documents.length !== 0) {
            yield put(setDocumentsToValidate(documents.content));
            yield put(setDocumentsToValidateCount(documents.totalElements));
        }
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestDocumentsToValidateSaga() {
    yield takeLatest(REQUEST_DOCUMENTS_TO_VALIDATE, requestDocumentsToValidate);
}

export default [
    requestDocumentValidationSaga,
    requestDocumentsToValidateSaga
]
