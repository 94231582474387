import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {createStructuredSelector} from "reselect";
import ToastElement from './toastElement';
import { makeSelectToastsList } from './selector';

const styles = () => ({
  container: {
    position: 'fixed',
    bottom: '24px',
    right: '24px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '5000',
  },
  toastRectangle: {
    backgroundColor: 'red',
  },
});

class ToastList extends React.PureComponent {
  render() {
    const { classes, toasts } = this.props;
    return (
      <div className={classes.container}>
        {toasts.map(toast => <ToastElement key={toast.id} toast={toast} />)}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  toasts: makeSelectToastsList(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ToastList));
