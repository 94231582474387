import {REQUEST_DOCUMENTS_TO_VALIDATE, SET_DOCUMENTS_TO_VALIDATE, VALIDATE_DOCUMENT} from "./constant";
import {SET_COUNT} from "../Dashboard/constants";

export const requestDocumentValidation = (decision, node) => ({
    type: VALIDATE_DOCUMENT,
    decision,
    node
});

export const requestDocumentsToValidate = (filterCriteria, pageConfig, node) => ({
    type: REQUEST_DOCUMENTS_TO_VALIDATE,
    filterCriteria,
    pageConfig,
    node
});

export const setDocumentsToValidate = (documentsToValidate, node) => ({
    type: SET_DOCUMENTS_TO_VALIDATE,
    documentsToValidate,
    node
});

export const setDocumentsToValidateCount = (count) => ({
    type: SET_COUNT,
    count
});