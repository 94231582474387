import React from "react";
import MoreHorizButton from "../../common/MoreHorizButton";
import { ReactComponent as EditIcon } from "../../images/icons/edit.svg";
import history from "../../utils/history";
import { endPoints } from "../../config/end-points";
import { USER_STORAGE } from "../../common/constant";
import { get } from "../../services/appStorage";
import DeleteIcon from "@material-ui/icons/Delete";

function UserActions(props) {
  const { user } = props;
  const currentUser = get(USER_STORAGE);

  const isAdmin = currentUser.role === "ROLE_ADMIN";
  const handleUpdate = (user) => {
    history.push({
      pathname: endPoints.addUser,
      state: {
        user: user,
      },
    });
  };

  const handleDelete = (user) => {
    props.onDeleteUser(user);
  };

  return (
    <MoreHorizButton
      items={
        isAdmin
          ? [
              {
                key: 1,
                value: "Modifier",
                onClick: () => {
                  handleUpdate(user);
                },
                icon: <EditIcon style={{ color: "#10277C" }} />,
              },
              {
                key: 2,
                value: "Supprimer",
                onClick: () => {
                  handleDelete(user);
                },
                icon: <DeleteIcon style={{ color: "#10277C" }} />,
              },
            ]
          : [
              {
                key: 1,
                value: "Modifier",
                onClick: () => {
                  handleUpdate(user);
                },
                icon: <EditIcon style={{ color: "#10277C" }} />,
              },
            ]
      }
    />
  );
}

export default UserActions;
