import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";
import {apiEndPoints} from "../../config/end-points";
import {addToast} from "../Toast/actions";
import {STATUS_ERROR, STATUS_SUCCESS} from "../Toast/constants";
import {ADD_DOCUMENT_TYPE, DELETE_DOCUMENT_TYPE} from "./constants";
import {requestDocumentTypes} from "../PopupContainer/action";

export function* requestAddDocumentType(action) {

    const {documentType} = action;

    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(documentType),
    };


    try {
        yield call(request, apiEndPoints.addDocumentType, options);
        yield put(
            addToast({
                status: STATUS_SUCCESS,
                title: 'SUCCESS',
                message: 'Votre opération a été exécutée avec succès',
            }),
        );
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestAddDocumentTypeSaga() {
    yield takeLatest(ADD_DOCUMENT_TYPE, requestAddDocumentType);
}


export function* requestDeleteDocumentType(action) {

    const {id} = action;

    const options = {
        method: 'DELETE',
        headers: {
        },
        body: JSON.stringify(id),
    };


    try {
        yield call(request, apiEndPoints.deleteDocumentType(id), options);
        yield put(requestDocumentTypes());
        yield put(
            addToast({
                status: STATUS_SUCCESS,
                title: 'SUCCESS',
                message: 'Votre opération a été exécutée avec succès',
            }),
        );
    } catch (error) {
        yield put(
            addToast({
                status: STATUS_ERROR,
                title: 'ERROR',
                message: 'Erreur dans le serveur',
            }),
        );
        console.error(error.message);
    }
}

export function* requestDeleteDocumentTypeSaga() {
    yield takeLatest(DELETE_DOCUMENT_TYPE, requestDeleteDocumentType);
}

export default [
    requestAddDocumentTypeSaga,
    requestDeleteDocumentTypeSaga
];