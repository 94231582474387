import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import DocPopup from "../../components/Popup/DocPopup";
import Filter from "../../components/Filter";
import Grid from "@material-ui/core/Grid";
import { filterValidationCriteria, STATUS, YEARS } from "../Dashboard/FilterConfig"
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header";
import ValidationActions from "../../components/ValidationActions";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestDocumentsToValidate, requestDocumentValidation } from "./action";
import {
    makeSelectDocumentFields,
    makeSelectDocumentTypes,
    makeSelectServices,
    makeSelectUsers
} from "../PopupContainer/selector";
import { makeSelectDocumentsToValidateCount, makeSelectDocumentsToValidateList } from "./selector";
import { requestDocumentTypes, requestServices, requestUsers } from "../PopupContainer/action";
import { Box } from "@material-ui/core";


const styles = {
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        height: '100%',
    },
    body: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        height: '100%',
        padding: 25,
        overflow: 'auto',
    },
};

class ValidationBoard extends Component {

    state = {
        open: false,
        searchCriteria: {
            search: '',
            service: '',
            status: '',
            document_type: '',
            date: '',
            responsable: '',
        },
        pageConfig: {
            page: 0,
            size: 10,
        },
    };

    componentDidMount() {
        this.props.requestDocumentsToValidate(this.state.searchCriteria, this.state.pageConfig);
        if (this.props.services && this.props.services.length === 0) {
            this.props.requestServices();
        }
        if (this.props.users && this.props.users.length === 0) {
            this.props.requestUsers();
        }
        if (this.props.documentTypes && this.props.documentTypes.length === 0) {
            this.props.requestDocumentTypes();
        }
    }

    onAddFileClick = () => {
        this.setState({ open: true })
    };


    handleClose = () => {
        this.setState({ open: false });
    };

    handlePageChange = (pageConfig) => {
        this.setState({ pageConfig: pageConfig });
        this.props.requestDocumentsToValidate(this.state.searchCriteria, pageConfig);
    };

    handleFilterConfigChange = (searchCriteria) => {
        this.setState({ searchCriteria: searchCriteria });
    };

    requestDocumentsToValidate = () => {
        this.props.requestDocumentsToValidate(this.state.searchCriteria, this.state.pageConfig);
    }

    requestDocumentValidation = (decision) => {
        this.props.requestDocumentValidation(decision);
    }

    render() {
        const { classes, documentsList, services, users, documentTypes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar />
                <Grid container className={classes.body}>

                    <Box style={{ display: "flex", width: '100%' }}>
                        <Filter
                            services={services}
                            users={users}
                            documentTypes={documentTypes}
                            date={YEARS}
                            status={STATUS}
                            handleSearch={this.requestDocumentsToValidate}
                            filterCriteria={filterValidationCriteria}
                            handleFilterChange={this.handleFilterConfigChange}
                        />
                        {false && <Header buttonLabel="Ajouter un document" onCallToAction={this.onAddFileClick} />}

                    </Box>
                    <DataTable
                        documents={documentsList}
                        totalDocuments={this.props.totalDocuments}
                        actions={
                            (document) => <ValidationActions
                                onValidateDocumentClick={this.requestDocumentValidation}
                                document={document} />
                        }
                        handlePageChange={this.handlePageChange}
                    />
                </Grid>
                <DocPopup open={this.state.open} handleClose={this.handleClose} />
            </div>
        );
    }

}

const mapDispatchToProps = {
    requestDocumentsToValidate,
    requestDocumentValidation,
    requestDocumentTypes,
    requestUsers,
    requestServices,
};

const mapStateToProps = createStructuredSelector({
    documentsList: makeSelectDocumentsToValidateList(),
    totalDocuments: makeSelectDocumentsToValidateCount(),
    services: makeSelectServices(),
    users: makeSelectUsers(),
    documentTypes: makeSelectDocumentTypes(),
    secondaryFields: makeSelectDocumentFields(),
});


ValidationBoard.propTypes = {
    requestDocumentsToValidate: PropTypes.func,
    requestDocumentValidation: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(withStyles(styles)(ValidationBoard));