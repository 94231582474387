import React from "react";
import {ReactComponent as EditIcon} from '../../images/icons/edit.svg';
import {ReactComponent as DeleteIcon} from '../../images/icons/trash.svg';
import MoreHorizButton from "../../common/MoreHorizButton";
import history from "../../utils/history";
import {endPoints} from "../../config/end-points";

function FieldTypeActions(props) {

    const { fieldType } = props;



    const handleUpdate= (fieldType) => {

        history.push({
            pathname: endPoints.addFieldType,
            state: {
                fieldType: fieldType,
            },
        });
    };

    return (
        <MoreHorizButton
            items={[
                {
                    key: 1,
                    value: "Modifier",
                    onClick: () => {handleUpdate(fieldType)},
                    icon: <EditIcon style={{color: '#10277C'}}/>,
                },
                {
                    key: 2,
                    value: "Supprimer",
                    onClick: () => {
                        props.requestDeleteFieldType(fieldType.id);
                        props.requestFieldTypes();
                    },
                    icon: <DeleteIcon style={{color: '#10277C'}}/>,
                },
            ]}
        />
    );

}

export default FieldTypeActions;
