import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Popup from "../Popup";

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typo: {
        fontFamily: 'Noto Sans',
        fontSize: 28,
        display: 'flex',
        alignItems: 'center',
        color: '#2A69BC',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveBtn: {
        color: "white !important",
        backgroundColor: "#10277C",
        width: "100%",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#C5DCFA87",
            boxShadow: "none",
        },
    },
};

export const Comment = ({ comments }) => (
    <Grid container>
        {comments?.map((comment) => (
            <Fragment key={comment.id}>
                <Grid item xs={6}>
                    <Typography display="inline" variant="subtitle1">
                        {`Le ${comment.createdAt?.split(" ")[0]} à ${comment.createdAt?.split(" ")[1]} par ${comment.user?.firstName} ${comment.user?.lastName} :`}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography display="inline" variant="subtitle1">
                        {comment.message}
                    </Typography>
                </Grid>
            </Fragment>))}
    </Grid>
);

class DocumentView extends Component {
    handleViewDocument = (link) => {
        window.open(link, "_blank");
    };
    render() {
        const { open, handleClose, selectedDocument: document } = this.props;
        return (
            <Popup
                title="Détail document"
                fullWidth
                open={open}
                handleClose={handleClose}
            >
                <div style={{ margin: '0 5% 0 10%' }}>

                    <Grid container style={{ width: "100%" }} spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant="h2" style={{ marginBottom: 60 }}>{document?.fields && document?.fields["Titre"]}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography display="inline" variant="subtitle1" style={{ marginBottom: 60, float: "right" }}>ID de pièce : {document?.id}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography display="inline" variant="subtitle1" style={{ fontWeight: "bold" }}>Service :</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography display="inline" variant="subtitle1">{document?.service}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography display="inline" variant="subtitle1" style={{ fontWeight: "bold" }}>Type de document :</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography display="inline" variant="subtitle1">{document?.documentType}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography display="inline" variant="subtitle1" style={{ fontWeight: "bold" }}>Validateurs :</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography display="inline" variant="subtitle1">{document?.validators.reduce((prev, curr) => prev + ", " + curr.firstName + " " + curr.lastName, "")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography display="inline" variant="subtitle1" style={{ color: "rgb(132, 132, 132)" }}>Champs Additionnels :</Typography>
                        </Grid>
                        {Object.keys(document?.fields).filter(k => k !== "Titre")?.map(key => (
                            <Fragment key={key}>
                                <Grid item xs={4} >
                                    <Typography display="inline" variant="subtitle1" style={{ fontWeight: "bold" }}>{`${key} :`}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography display="inline" variant="subtitle1">{document?.fields[key]}</Typography>
                                </Grid>
                            </Fragment>
                        ))}
                        <Grid item xs={12} container style={{ height: 800, overflowY: 'auto' }}>
                            {document.urls.map(link => <React.Fragment key={link}>
                                <Grid item xs={8}>
                                    <embed
                                        src={link}
                                        width="100%"
                                        height="300" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.handleViewDocument(link)}
                                        startIcon={<GetAppIcon />}
                                    >
                                        Télecharger le fichier
                                    </Button>
                                </Grid>
                            </React.Fragment>)}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography display="inline" variant="subtitle1" style={{ color: "rgb(132, 132, 132)" }}>Notes :</Typography>
                        </Grid>
                        <Comment comments={document?.comments} />
                    </Grid>
                </div>
            </Popup>
        );
    }
}

export default withStyles(styles)(withRouter(DocumentView));
