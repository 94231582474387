import {ADD_USER, DELETE_USER} from "./constants";

export const requestAddUser = (user, node) => ({
    type: ADD_USER,
    user,
    node
});
export const requestDeleteUser = (user, node) => ({
    type: DELETE_USER,
    user,
    node
});