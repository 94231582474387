import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeSelectServices } from "../PopupContainer/selector";
import { requestServices } from "../PopupContainer/action";
import history from "../../utils/history"
import { endPoints } from "../../config/end-points";
import { requestAddDepartment, requestDeleteDepartment } from "../AddServicePage/action";
import DepartmentActions from "../../components/DepartmentTable/DepartmentActions";
import DepartmentTable from "../../components/DepartmentTable";




const styles = {
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        height: '100%' ,
    },
    body: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        height: '100%' ,
        padding: 25,
        overflow: 'auto',
    },
};

class DepartmentsPage extends Component {

    state = {
        open: false,
        selectedDepartment: {
            id: "",
            name: "",
        },
        pageConfig: {
            page: 0,
            size: 1,
        },
    };

    componentWillMount() {
        this.props.requestServices(this.state.pageConfig);
    }

    componentDidMount() {
        this.props.requestServices(this.state.pageConfig);
    }

    onAddDepartment = () => {
        history.push({
            pathname: endPoints.addDepartment,
        });
    };

    handlePageChange = (pageConfig) => {
        this.setState({ pageConfig: pageConfig });
        this.props.requestServices(pageConfig);
    };

    render() {
        const { classes, departmentsList } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar />
                <Grid container className={classes.body}>
                    <Header buttonLabel='Ajouter un service' onCallToAction={this.onAddDepartment} />
                    <DepartmentTable
                        departmentsList={departmentsList}
                        actions={(department) =>
                            <DepartmentActions
                                requestDeleteDepartment={this.props.requestDeleteDepartment}
                                requestDepartments={this.props.requestServices}
                                department={department}
                            />}
                        handlePageConfigChange={this.handlePageChange}
                    />
                </Grid>
            </div>
        );
    }

}

const mapDispatchToProps = {
    requestDeleteDepartment,
    requestAddDepartment,
    requestServices
};

const mapStateToProps = createStructuredSelector({
    departmentsList: makeSelectServices(),
});


DepartmentsPage.propTypes = {
    requestAddDepartment: PropTypes.func,
    requestServices: PropTypes.func,
    requestDeleteDepartment: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DepartmentsPage));