import {initialState} from "./reducer";
import {createSelector} from "reselect";

const selectState = state => state.get('documentFields', initialState);

const selectFieldTypes = createSelector(
    selectState,
    fieldTypesState =>
        fieldTypesState.get('fieldTypes')
            ? fieldTypesState
                .get('fieldTypes')
                .toJS()
            : [],
);

export const makeSelectFieldTypes = () => selectFieldTypes;