import React, { Component } from "react";
import { ReactComponent as Visibility } from "../../images/icons/visibilityAction.svg";
import { ReactComponent as EditIcon } from "../../images/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../images/icons/trash.svg";
import HistoryIcon from "@material-ui/icons/History";
import MoreHorizButton from "../../common/MoreHorizButton";
import { get } from "../../services/appStorage";
import { USER_STORAGE } from "../../common/constant";
import { withRouter } from "react-router";

function DocumentActions(props) {
  const { document } = props;
  const user = get(USER_STORAGE);
  const departement = user.departments.find(
    (d) => d.department?.id === document.documentType?.department?.id
  );
  const { match, location, history } = props;
  return (
    <MoreHorizButton
      items={
        departement?.permission === "WRITE" || user.role === "ROLE_ADMIN"
          ? [
              {
                key: 1,
                value: "Consulter",
                onClick: () => props.onViewClick(document),
                icon: <Visibility style={{ color: "#10277C" }} />,
              },
              {
                key: 2,
                value: "Modifier",
                onClick: () => {
                  props.onAddFileClick(document);
                },
                icon: <EditIcon style={{ color: "#10277C" }} />,
              },
              {
                key: 3,
                value: "Supprimer",
                onClick: () => {
                  props.onDeleteDocument(document);
                },
                icon: <DeleteIcon style={{ color: "#10277C" }} />,
              },
              {
                key: 4,
                value: "Historique",
                onClick: () => {
                  props.onHistoryClick(document);
                },
                icon: <HistoryIcon style={{ color: "#10277C" }} />,
              },
            ]
          : [
              {
                key: 1,
                value: "Consulter",
                onClick: () => props.onViewClick(document),
                icon: <Visibility style={{ color: "#10277C" }} />,
              },
            ]
      }
    />
  );
}

export default withRouter(DocumentActions);
