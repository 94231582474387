import request from "../../utils/request";
import { apiEndPoints } from "../../config/end-points";
import { addToast } from "../Toast/actions";
import { STATUS_ERROR } from "../Toast/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_USER, DELETE_USER } from "./constants";
import { requestUsers, setUsers } from "../PopupContainer/action";

export function* requestAddUser(action) {
  const user = action.user;

  delete user.enable;
  delete user.departments;

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  try {
    yield call(request, apiEndPoints.addUser, options);
    yield put(requestUsers());
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}

export function* requestDeleteUser(action) {
  const user = action.user;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    yield call(request, apiEndPoints.deleteUser(user), options);
    yield put(requestUsers());
  } catch (error) {
    yield put(
      addToast({
        status: STATUS_ERROR,
        title: "ERROR",
        message: "Erreur dans le serveur",
      })
    );
    console.error(error.message);
  }
}
export function* requestAddUserSaga() {
  yield takeLatest(ADD_USER, requestAddUser);
}
export function* requestDeleteUserSaga() {
  yield takeLatest(DELETE_USER, requestDeleteUser);
}

export default [requestAddUserSaga, requestDeleteUserSaga];
