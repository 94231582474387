import React from 'react';
import { withStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseButton from "../../common/CloseButton";
import DialogContent from "@material-ui/core/DialogContent";


export const styles = {
    header: {
        marginTop: '25px',
        marginBottom: '25px',
    },
    footer: {
        height: '125px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 40px',
    },
    title: {
        fontFamily: 'Noto Sans',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: 0,
        textAlign: 'left',
        width: '255px',
        height: '33px',
    },
};

class Popup extends React.Component {
    render() {
        const {
            classes, handleClose, open, title, children, footer
        } = this.props;
        return (
            <Dialog
                id="popupParcelDetails-modal"
                open={open}
                onClose={handleClose}
                maxWidth="md"
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
                disableBackdropClick
                //scroll="body"
            >
                    <DialogTitle style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)'}}>
                        <CloseButton
                            style={{ transform: 'translate(-16px, -16px)', marginTop: 15 }}
                            handleClose={handleClose}
                        />
                        <span className={classes.title}>{title}</span>
                    </DialogTitle>
                <DialogContent style={{display: 'flex', padding: 24, flexDirection: 'row'}}>
                    {children}
                </DialogContent>
                <div className={classes.footer}>
                    {footer}
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Popup);
