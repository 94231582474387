import 'whatwg-fetch';
import apiPathService from '../services/apiPath';
import { get } from '../services/appStorage';
import history from './history';
import loginDataStorageService from '../services/security/loginDataStorageService';

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export const ACCESS_TOKEN_STORAGE = 'access_token';

const parseJSON = response => {
    return response.text().then(function (text) {
        return text ? JSON.parse(text) : {};
    });
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    if (response.status === 401 || response.status === 403) {
        loginDataStorageService.clearLoginData();
        history.push('/');
    }

    const error = new Error(response.statusText);
    return (
        response
            .text()
            // To handle empty responses: should not throw an error at this stage.
            .then(body => (body ? JSON.parse(body) : {}))
            .then(parsedBody => {
                error.response = parsedBody;
                error.status = response.status;
                throw error;
            })
    );
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const request = (url, options) => {
    const opt = options;
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE);
    if (opt.headers && !opt.headers.Authorization) {
        opt.headers.Authorization = `${accessToken}`;
    } else {
        opt.headers = {
            Authorization: `${accessToken}`,
        };
    }
    const baseApiPath = apiPathService.getApiBasePath();

    return fetch(`${baseApiPath}${url}`, opt)
        .then(checkStatus)
        .then(parseJSON)
        .catch(error => {
            throw error;
        });
};

export default request;
