import { put } from "redux-saga/effects";
import loginDataStorageService from "../services/security/loginDataStorageService";
import apiPathService from "../services/apiPath";
import { endPoints } from "../config/end-points";
import { clear, get } from "../services/appStorage";
import { loginFailed } from "../containers/Login/actions";
import { ACCESS_TOKEN_STORAGE } from "../common/constant";
import history from "./history";

const parseJSON = (response) =>
  response !== undefined ? response.json() : response;

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401 || response.status === 403) {
    clear(ACCESS_TOKEN_STORAGE);
    put(loginFailed("UNAUTHORIZED"));
  }

  const error = new Error(response.statusText);
  return (
    response
      .text()
      // To handle empty responses: should not throw an error at this stage.
      .then((body) => (body ? JSON.parse(body) : {}))
      .then((parsedBody) => {
        error.response = parsedBody;
        error.status = response.status;
        throw error;
      })
  );
};

export function logIn() {
  const baseApiPath = apiPathService.getApiBasePath();

  const accessToken = window.localStorage.getItem(ACCESS_TOKEN_STORAGE);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`,
    },
  };
  return fetch(baseApiPath + endPoints.me, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
}

export function logOut() {
  loginDataStorageService.clearLoginData();
  history.push("/");
}
