import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { endPoints } from "../../config/end-points";
import history from "../../utils/history"



const styles = {
    textField: {
        height: 56,
        borderRadius: '8px !important',
    },
    approveBtn: {
        float: "right",
        color: "white",
        backgroundColor: "#10277C",
        width: "100%",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#C5DCFA87",
            boxShadow: "none",
        },
    },
    typography: {
        margin: 0,
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: '138%',
        letterSpacing: '0.0075em',
        textAlign: 'left',
    }
};

class AddService extends Component {
    state = {
        department: {
            name: ''
        },
    };

    componentWillMount() {
        if (this.props.department) {
            const department = this.initDepartment(this.props.department);
            this.setState({ department: department });
        }
    }

    initDepartment = (selectedDepartment) => {
        let department = {
            name: '',
        };
        if (selectedDepartment) {
            department = {
                id: selectedDepartment.id,
                name: selectedDepartment.name,
            };
        }

        this.setState({ department: department });
        return department;
    };


    componentDidUpdate(
        prevProps,
        prevState,
        snapshot
    ) {
        if (this.props.department &&
            prevProps.department !== this.props.department) {
            const department = this.initDepartment(this.props.department);
            this.setState({ department: department });
        }
    }

    onSaveAction = () => {
        if (this.state.department.name !== "") {
            this.props.requestAddDepartment(this.state.department);
            this.initDepartment();
            history.push({
                pathname: endPoints.departments,
            });
        }
    };


    render() {
        const { department } = this.state;
        const { classes } = this.props;

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                <div style={{ margin: 12, }}>
                    <p className={classes.typography}>
                        Veuillez remplir les informations suivantes, afin de créer un nouveau service
                    </p>
                </div>
                <form style={{ width: '100%' }}>
                    <Grid container direction="column" style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: 'center' }}>
                        <Grid
                            item
                            style={{ display: "flex", flexDirection: "column", width: "60%", justifyContent: "center" }}
                            lg={12}
                        >
                            <FormControl style={{ margin: 16, width: "100%", height: 56 }} >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    value={department.name}
                                    type="text"
                                    label="Nom du service"
                                    onChange={event =>
                                        this.setState({ department: { ...department, name: event.target.value } })
                                    }
                                    name="name"
                                />
                            </FormControl>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '0px 20px' }}>
                                <Button
                                    id="save-doc-button"
                                    variant="contained"
                                    className={classes.approveBtn}
                                    onClick={this.onSaveAction}
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(AddService);