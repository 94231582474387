import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button/Button";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PRIMITIVE_TYPES from "./constant";
import history from "../../utils/history";
import { Box, Typography } from "@material-ui/core";
import DropZoneComp from "../DropDownFile/FileComponent";
import img from "../../images/picture.png";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { USER_STORAGE } from "../../common/constant";
import { get } from "../../services/appStorage";

const grid = 8;

const styles = {
  form: {
    width: "100%",
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  textField: {
    // width: 392,
    height: 56,
    borderRadius: "8px !important",
  },
  approveBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  editBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  typography: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: "0.0075em",
    textAlign: "left",
  },
  fileContainer: {
    display: "flex",
    width: "100%",
    height: "184px",
    border: "1px dashed rgba(42, 105, 188, 0.37)",
    borderRadius: 8,
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  displayFile: {
    display: "flex",
    width: "100%",
    height: 65,
    borderRadius: 8,
    margin: "8px 15px",
    padding: "0px 8px",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#C5DCFA66 40%",
  },
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
class AddFieldType extends Component {
  state = {
    type: {
      name: "",
      type: "",
      values: [],
      file: null,
    },
    currentValue: "",
    editValues: [],
    valueListToEdit: "",
  };

  componentWillMount() {
    if (this.props.type) {
      const type = this.initState(this.props.type);
      this.setState({ type: type });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.type && prevProps.type !== this.props.type) {
      const type = this.initState(this.props.type);
      this.setState({ type: type });
    }
  }

  onAddValue = () => {
    let values = this.state.type.values;
    let { currentValue } = this.state;
    if (
      !values.includes(currentValue) &&
      (currentValue !== "" || currentValue.length === 0 || !currentValue.trim())
    ) {
      values.push(currentValue);
      const type = {
        ...this.state.type,
        values: values,
      };
      this.setState({ type: type, currentValue: "" });
    }
  };
  initState = (selectedType) => {
    let type = {
      name: "",
      type: "",
      file: null,
      values: [],
    };

    if (selectedType) {
      type = {
        id: selectedType.id,
        name: selectedType.name,
        type: selectedType.type,
        values: selectedType.fieldTypeValues,
        file: selectedType.file,
      };
    }
    this.setState({ type });
    return type;
  };

  handleFileDisplay = (file) => {
    this.setState({ type: { ...this.state.type, file: file[0] } });
  };

  onDeleteFile = () => {
    this.setState({ type: { ...this.state.type, file: null } });
  };

  onSaveAction = () => {
    if (this.state.type.name !== "" && this.state.type.type !== "") {
      this.props.addFieldType({
        ...this.state.type,
        values: this.state.type.values.map((v) =>
          typeof v === "object" ? v.name : v
        ),
      });
      this.initState();
      history.push({
        pathname: "types",
      });
    }
  };

  handleChangeValueListEdit = (event) => {
    this.setState({ ...this.state, valueListToEdit: event.target.value });
  };

  editListValue = (edit, index) => () => {
    this.setState({
      ...this.state,
      editValues: this.state.type.values.map((v, i) =>
        i === index ? edit : false
      ),
      valueListToEdit: edit
        ? this.state.type.values[index]?.name
        : this.state.valueListToEdit,
    });
  };
  resetEditValue = () => {
    this.setState({ ...this.state, editValues: [], valueListToEdit: "" });
  };
  submitEditListValue = (id) => () => {
    this.props.editListValue({
      fieldValueId: id,
      newValue: this.state.valueListToEdit,
    });
    this.setState({
      ...this.state,
      type: {
        ...this.state.type,
        values: this.state.type.values.map((v) =>
          v.id === id ? { id: v.id, name: this.state.valueListToEdit } : v
        ),
      },
      editValues: [],
      valueListToEdit: "",
    });
  };
  getListStyle = (isDraggingOver) => ({
    background: "#FFFFFF",
    padding: grid,
    width: '90%',
  });
  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    display: "flex",
    width: "100%",
    alignItems: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#FFFFFF",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderValues = reorder(
      this.state.type?.values,
      result.source.index,
      result.destination.index
    );

    this.setState({
      ...this.state,
      type: {
        ...this.state.type,
        values: orderValues,
      },
    });
  };
  render() {
    const { classes } = this.props;
    const { type } = this.state;
    const user = get(USER_STORAGE);
    const isAdmin = user?.role === 'ROLE_ADMIN';


    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div style={{ margin: 12 }}>
          <p className={classes.typography}>
            Veuillez remplir les informations suivantes, afin de créer un
            nouveau type de champ
          </p>
        </div>
        <form className={classes.form}>
          <Grid container className={classes.gridContainer}>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={12}
            >
              <FormControl style={{ margin: 16, width: "100%", height: 56 }}>
                <TextField
                  size="small"
                  required
                  variant="outlined"
                  value={type.name}
                  type="text"
                  label="Nom de type"
                  inputProps={{
                    className: classes.typography,
                  }}
                  onChange={(event) =>
                    this.setState({
                      type: { ...type, name: event.target.value },
                    })
                  }
                  name="name"
                />
              </FormControl>
              <FormControl
                style={{ margin: 16, width: "100%" }}
                variant="outlined"
                required
              >
                <InputLabel style={{ top: -8 }}>
                  Type du nouveau type
                </InputLabel>
                <Select
                  margin="dense"
                  onChange={(event) =>
                    this.setState({
                      type: { ...type, type: event.target.value },
                    })
                  }
                  label="Type du nouveau type "
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                  value={type.type}
                  inputProps={{
                    className: classes.typography,
                  }}
                >
                  {PRIMITIVE_TYPES &&
                    PRIMITIVE_TYPES.map((elt, index) => (
                      <MenuItem key={index} value={elt}>
                        <p className={classes.typography}>{elt}</p>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {type && type.values?.length !== 0 && (
              <Grid
                item
                style={{ display: "flex", justifyContent: "center" }}
                lg={12}
              >
                <Table
                  style={{
                    margin: "0px 16px",
                    padding: 2,
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <p
                          style={{ fontWeight: "bold" }}
                          className={classes.typography}
                        >
                          Liste des valeurs
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                          >
                            {type?.values?.map((row, index) => (
                              <Draggable
                                key={typeof row === "object" ? row.id : row}
                                draggableId={
                                  "do" +
                                  (typeof row === "object" ? row.id : row)
                                }
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Box style={{ flexGrow: 1 }}>
                                      {!this.state.editValues[index] && (
                                        <p className={classes.typography}>
                                          {typeof row === "object"
                                            ? row.name
                                            : row}
                                        </p>
                                      )}
                                      {typeof row === "object" &&
                                        this.state.editValues[index] && (
                                          <TextField
                                            size="small"
                                            id="outlined-basic"
                                            label="value"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.valueListToEdit}
                                            onChange={
                                              this.handleChangeValueListEdit
                                            }
                                          />
                                        )}
                                    </Box>
                                    {typeof row === "object" && (
                                      <Box>
                                        {!this.state.editValues[index] && (
                                          <IconButton
                                            aria-label="edit"
                                            className={classes.margin}
                                            onClick={this.editListValue(
                                              true,
                                              index
                                            )}
                                          >
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                        )}
                                        {this.state.editValues[index] && (
                                          <Box style={{ display: "flex" }}>
                                            <Button
                                              style={{ flexGrow: 1 }}
                                              variant="contained"
                                              className={classes.editBtn}
                                              onClick={this.submitEditListValue(
                                                row.id
                                              )}
                                            >
                                              <p className={classes.typography}>
                                                Enregistrer
                                              </p>
                                            </Button>
                                            <IconButton
                                              aria-label="edit"
                                              className={classes.margin}
                                              onClick={this.resetEditValue}
                                            >
                                              <ClearIcon fontSize="small" />
                                            </IconButton>
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </TableBody>
                </Table>
              </Grid>
            )}
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl
                style={{ margin: 16, width: "100%" }}
                variant="outlined"
              >
                <TextField
                  variant="outlined"
                  value={this.state.currentValue}
                  type="text"
                  size="small"
                  label="Nouvelle valeur"
                  onChange={(event) => {
                    this.setState({ currentValue: event.target.value });
                  }}
                  name="currentValue"
                  inputProps={{
                    className: classes.typography,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                <Button
                  id="save-doc-button"
                  variant="contained"
                  className={classes.approveBtn}
                  onClick={this.onAddValue}
                >
                  <p className={classes.typography}>Ajouter la valeur</p>
                </Button>
              </div>
            </Grid>
            {isAdmin && <><Grid item lg={12}>
              <Typography
                align="left"
                style={{ color: "#848484", margin: "16px 0px 0px 16px" }}
              >
                Chargement du document
              </Typography>
            </Grid>
              <Grid item className={classes.fileContainer} lg={12}>
                <DropZoneComp
                  onFileDrop={this.handleFileDisplay}
                  acceptedTypes={[".csv"]}
                />
              </Grid>
              {this.state.type.file && (
                <Grid item className={classes.displayFile} lg={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={img}
                      alt="doc"
                      style={{ height: 35, marginRight: 8 }}
                    />
                    <Typography>{this.state.type.file.name}</Typography>
                  </div>
                  <div>
                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="delete"
                        onClick={() => this.onDeleteFile()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              )}</>}
            <Grid item lg={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 20px",
                }}
              >
                <Button
                  id="save-doc-button"
                  variant="contained"
                  className={classes.approveBtn}
                  onClick={this.onSaveAction}
                >
                  <p className={classes.typography}>Enregistrer</p>
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(AddFieldType);
