import './App.css';
import React from "react";
import history from './utils/history';
import { ConnectedRouter } from 'react-router-redux';
import ReactDOM from 'react-dom';
import App from "./containers/App";


// Create redux store with history
const MOUNT_NODE = document.getElementById('root');


const render = () => {
    ReactDOM.render() (
                  <ConnectedRouter history={history}>
                        <App/>
                  </ConnectedRouter>,
        MOUNT_NODE
  );
};

export default App;
