import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import withStyles from "@material-ui/core/styles/withStyles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button/Button";
import Chip from "@material-ui/core/Chip";
import history from "../../utils/history";
import { apiEndPoints, endPoints } from "../../config/end-points";
import { Checkbox, FormControlLabel, ListItemText, Switch } from "@material-ui/core";

const styles = {
  textField: {
    // width: 392,
    borderRadius: "8px !important",
  },
  approveBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  typography: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "138%",
    letterSpacing: "0.0075em",
    textAlign: "left",
  },
};

class AddUser extends Component {
  state = {
    user: {
      id: null,
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      serviceIds: [],
      fieldTypes: [],
      password: null,
      role: "ROLE_USER",
    },
    visiblePassword: false,
    selectedServices: [],
    selectedServicesP: {},
  };

  componentDidMount() {
    this.props.requestTypes();
  }
  handleChangeCh = (event) => {
    this.setState({
      ...this.state,
      selectedServicesP: {
        ...this.state.selectedServicesP,
        [event.target.name]: event.target.checked,
      },
    });
  };
  initUser = (selectedUser) => {
    if (selectedUser) {
      let user = selectedUser;
      let serviceIds = [];
      // eslint-disable-next-line array-callback-return
      selectedUser &&
        selectedUser.departments &&
        selectedUser.departments.map((service) => {
          serviceIds.push(service.id);
        });
      user["serviceIds"] = serviceIds;
      return user;
    }
  };

  initSelectedServices = (departments) => {
    let selectedServices = [];
    if (departments && departments.length !== 0) {
      selectedServices = departments.map((service) => service?.department);
    }
    return selectedServices;
  };

  componentWillMount() {
    if (this.props.selectedUser) {
      const selectedUser = this.initUser(this.props.selectedUser);
      const selectedServices = this.initSelectedServices(
        this.props.selectedUser.departments
      );
      this.setState({
        user: selectedUser,
        selectedServices: selectedServices,
        selectedServicesP: this.props.selectedUser.departments.reduce(
          (prev, curr) => ({
            ...prev,
            [curr?.department?.id]: curr.permission === "READ" ? false : true,
          }),
          {}
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedUser &&
      prevProps.selectedUser !== this.props.selectedUser
    ) {
      const selectedServices = this.initSelectedServices(
        this.props.selectedUser.departments
      );
      this.setState({
        user: this.props.selectedUser,
        selectedServices: selectedServices,
        selectedServicesP: this.props.selectedUser.departments.reduce(
          (prev, curr) => ({
            ...prev,
            [curr?.department?.id]: curr.permission === "READ" ? false : true,
          }),
          {}
        ),
      });
    }
  }

  handleSubmit = () => {
    this.props.requestAddUser({
      ...this.state.user,
      services: Object.keys(this.state.selectedServicesP).map((key) => ({
        serviceId: parseInt(key),
        permission: this.state.selectedServicesP[key] ? "WRITE" : "READ",
      })),
      serviceIds: undefined,
    });
    this.props.requestUsers({ page: 0, size: 10 });
    history.push("/home?useTab=1");
  };

  handleClickShowPassword() {
    this.setState((state) => ({ visiblePassword: !state.visiblePassword }));
  }

  handleChange = (event) => {
    let user = {
      ...this.state.user,
      [event.target.name]: event.target.value,
    };
    this.setState({ user: user });
  };

  handleServicesChange = (event) => {
    const user = {
      ...this.state.user,
      serviceIds: event.target.value.map((v) => v.id),
    };
    this.setState({
      user: user,
      selectedServices: event.target.value,
      selectedServicesP: event.target.value.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: this.state.selectedServicesP[curr.id] ? true : false,
        }),
        {}
      ),
    });
  };

  handleDeleteChip = (id) => {
    let serviceIds = this.state.user.serviceIds;
    let selectedServices = this.state.selectedServices;
    const index = serviceIds.indexOf(id);
    if (index > -1) {
      serviceIds.splice(index, 1);
      selectedServices.splice(id, 1);
    }
    this.setState({
      user: { ...this.state.user, serviceIds: serviceIds },
      selectedServices: selectedServices,
    });
  };

  render() {
    const { classes, services, selectedUser, typesList } = this.props;
    const { user, selectedServices } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ margin: 12 }}>
          <p className={classes.typography}>
            Veuillez remplir les informations suivantes, afin de créer un nouvel
            utilisateur
          </p>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Grid
            container
            direction="row"
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl style={{ margin: 16, width: "100%", height: 56 }}>
                <TextField
                  required
                  variant="outlined"
                  defaultValue={selectedUser && selectedUser.firstName}
                  value={user.firstName}
                  type="text"
                  label="Prénom"
                  onChange={this.handleChange}
                  name="firstName"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl style={{ margin: 16, width: "100%", height: 56 }}>
                <TextField
                  size="small"
                  required
                  variant="outlined"
                  label="Nom de famille"
                  defaultValue={selectedUser && selectedUser.lastName}
                  value={user.lastName}
                  type="text"
                  onChange={this.handleChange}
                  name="lastName"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl style={{ margin: 16, width: "100%", height: 56 }}>
                <TextField
                  size="small"
                  required
                  variant="outlined"
                  label="Nom d'utilisateur"
                  value={user.username}
                  defaultValue={selectedUser && selectedUser.username}
                  type="text"
                  onChange={this.handleChange}
                  name="username"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl style={{ margin: 16, width: "100%", height: 56 }}>
                <TextField
                  size="small"
                  required
                  variant="outlined"
                  label="Email"
                  defaultValue={selectedUser && selectedUser.email}
                  value={user.email}
                  type="email"
                  onChange={this.handleChange}
                  name="email"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl
                style={{ margin: 16, width: "100%", height: 56 }}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  style={{ top: -8 }}
                >
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  margin="dense"
                  required
                  label="Mot de passe"
                  value={user.password}
                  className={classes.textField}
                  type={this.state.visiblePassword ? "text" : "password"}
                  onChange={this.handleChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => {
                          this.handleClickShowPassword();
                        }}
                      >
                        {this.state.visiblePassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <FormControl
                style={{ margin: 16, width: "100%" }}
                variant="outlined"
              >
                <InputLabel style={{ top: -8 }}>Services</InputLabel>
                <Select
                  margin="dense"
                  required
                  multiple
                  onChange={this.handleServicesChange}
                  label="Services"
                  name="department"
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                  defaultValue={services.filter((s) =>
                    selectedServices.map((sv) => sv.id).includes(s.id)
                  )}
                >
                  {services &&
                    services.map((service) => (
                      <MenuItem key={service.id} value={service}>
                        {service.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px 16px",
              height: 56,
            }}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
              >
                <InputLabel>Champs personnalisés</InputLabel>
                <Select
                  multiple
                  renderValue={(selected) => typesList ? selected.map(s => typesList.find(type => type.id === s)?.name).join(", ") : ""}
                  label="Champs personnalisés"
                  defaultValue={[]}
                  onChange={this.handleChange}
                  name="fieldTypes"
                  value={user.fieldTypes}
                >
                  {typesList && typesList.map((a) => (
                    <MenuItem value={a.id} key={a.id}>
                      <Checkbox
                        color="primary"
                        checked={
                          user.fieldTypes.indexOf(a.id) > -1
                        }
                      />
                      <ListItemText primary={a.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 16px",
                height: 56,
              }}
              lg={6}
              id="selectedServices"
            >
              {selectedServices &&
                selectedServices.map((service) => (
                  <FormControlLabel
                    key={service.id}
                    value={service.id}
                    control={
                      <Switch
                        value={this.state.selectedServicesP[service.id]}
                        checked={this.state.selectedServicesP[service.id]}
                        onChange={this.handleChangeCh}
                        name={service.id}
                        style={{
                          padding: 9,
                          color: this.state.selectedServicesP[service.id]
                            ? "#3f51b5"
                            : "#fafafa",
                        }}
                        color="primary"
                        defaultValue={this.state.selectedServicesP[service.id]}
                      />
                    }
                    label={"Editer les documents de " + service.name}
                    labelPlacement="start"
                  />
                ))}
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              lg={6}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.approveBtn}
                  id="submit"
                  value="Submit"
                  onClick={this.handleSubmit}
                >
                  Enregistrer
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(AddUser);
