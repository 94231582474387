import { LOGIN_FAILURE, LOGIN_REQUESTED, LOGIN_SUCCESS } from "./constants";

export const requestLogin = () => ({
  type: LOGIN_REQUESTED,
});

export const loginSuccess = (loginResponse) => ({
  type: LOGIN_SUCCESS,
  loginResponse,
});

export const loginFailed = (message) => ({
  type: LOGIN_FAILURE,
  message,
});
