import React, { Component } from "react";
import Sidebar from "../../components/Sidebar";
import withStyles from "@material-ui/core/styles/withStyles";
import AddService from "../../components/AddService";
import { requestAddDepartment, requestDeleteDepartment } from "./action";
import { createStructuredSelector } from "reselect";
import { makeSelectServices } from "../PopupContainer/selector";
import { connect } from "react-redux";
import { Box, Grid } from "@material-ui/core";


const styles = {
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        height: '100%',
    },
    body: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        height: '100%' ,
        padding: 25,
        overflow: 'auto',
    },
};

class AddServicePage extends Component {
    render() {
        const { classes } = this.props;
        const selectedDepartment = this.props.location.state ? this.props.location.state.department : null;

        return (
            <div className={classes.root}>
                <Sidebar />
                <Grid container className={classes.body}>
                    <Box className={classes.container}>
                        <Box style={{ width: 800 }}>
                            <AddService
                                requestAddDepartment={this.props.requestAddDepartment}
                                requestDeleteDepartment={this.props.requestDeleteDepartment}
                                department={selectedDepartment}
                            />
                        </Box>
                    </Box>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = {
    requestAddDepartment,
    requestDeleteDepartment,
};

const mapStateToProps = createStructuredSelector({
    departments: makeSelectServices(),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddServicePage));