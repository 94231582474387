import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent, SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./style.css";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { IconButton, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PostAddIcon from '@material-ui/icons/PostAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { Link, useLocation } from "react-router-dom";
import { USER_STORAGE } from "../../common/constant";
import { get } from "../../services/appStorage";
import loginDataStorageService from "../../services/security/loginDataStorageService";
import history from '../../utils/history';
import { ReactComponent as ArchiscanLogo } from "../../images/Archiscan.svg";
import { ReactComponent as FolderOpen } from "../../images/icons/folderOpen.svg";
import { ReactComponent as GroupIcon } from "../../images/icons/group.svg";
import { ReactComponent as LogoutIcon } from "../../images/icons/logout.svg";
import { ReactComponent as VectorIcon } from "../../images/icons/vector.svg";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';



const styles = {
  sectionTitle: {
    position: 'static',
    width: 131,
    height: 16,
    left: 12,
    top: 16,
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '158%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    margin: '0px 12px',
  },
  avatarBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: 40,
    marginBottom: 12,
    marginTop: 56,
    padding: '8px 35px 8px 20px',
  },
  username: {
    margin: 0,
    fontWeight: 400,
    fontSize: 18,
    color: '#FFFFFF'
  },
  userRole: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FFFFFF',
  }
};

const ROLES = {
  ROLE_ADMIN: 'Administrateur',
  ROLE_USER: 'Utilisateur',
};

function Sidebar(props) {
  const { classes } = props;
  const location = useLocation();
  const user = get(USER_STORAGE);
  const [collapsed, setCollapsed] = useState(false);

  const isAdmin = user.role === 'ROLE_ADMIN';

  const logOut = () => {
    loginDataStorageService.clearLoginData();
  };

  return (
    <div id="header">
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div style={{ display: 'flex', justifyContent: collapsed ? 'center' : 'flex-end', marginRight: 12 }}>
            <IconButton
              size="medium"
              onClick={() => setCollapsed(!collapsed)}
              edge="end"
              style={{ color: 'white' }}
            >
              <MenuIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32, visibility: collapsed ? 'hidden' : 'visible' }}>
            <ArchiscanLogo />
          </div>
          <div className={classes.avatarBox}>
            <div>
              <Avatar style={{ backgroundColor: 'rgba(197, 220, 250, 0.53)' }}>
                <VectorIcon />
              </Avatar>
            </div>
            <div style={{ marginLeft: 12, visibility: collapsed ? 'hidden' : 'visible' }}>
              <Typography className={classes.username}>{user.firstName + " " + user.lastName}</Typography>
              <Typography className={classes.userRole}>{ROLES[user.role]}</Typography>
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<FolderOpen />} active={location.pathname === "/home"}>
              <Typography>Documents</Typography>
              <Link to="/home" />
            </MenuItem>
            <MenuItem icon={<CheckCircleOutlineIcon />} active={location.pathname === "/validation"}>
              <Typography>Documents à valider</Typography>
              <Link to="/validation" />
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">

            <SubMenu title={<Typography> Paramètres </Typography>} icon={<SettingsOutlinedIcon />}>
              {isAdmin && <><MenuItem icon={<GroupIcon />} active={location.pathname === "/users"}>
                <Typography>Gestion des affectations</Typography>
                <Link to="/users" />
              </MenuItem><MenuItem icon={<BusinessCenterIcon />} active={location.pathname === "/services"}>
                  <Typography>Services</Typography>
                  <Link to="/services" />
                </MenuItem><MenuItem active={location.pathname === "/documentTypes"}>
                  <Typography>Référentiel des documents</Typography>
                  <Link to="/documentTypes" />
                </MenuItem></>
              }
              <MenuItem icon={<PostAddIcon />} active={location.pathname === "/types"}>
                <Typography>Champs personnalisés</Typography>
                <Link to="/types" />
              </MenuItem>
            </SubMenu>

            <MenuItem onClick={() => logOut()} icon={<LogoutIcon />}>
              <Typography>Se déconnecter</Typography>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

export default withStyles(styles)(Sidebar);
