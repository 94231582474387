export const filterCriteria = [
  {
    id: "responsable",
    name: "Ajouté par",
    propsName: "users",
    displayName: "username",
    filterField: "",
  },
  {
    id: "service",
    name: "Service",
    propsName: "services",
    displayName: "name",
    filterField: "",
    select: true,
  },
  {
    id: "documentType",
    name: "Type de document",
    propsName: "documentTypes",
    displayName: "name",
    filterField: "",
  },
  {
    id: "status",
    name: "Status",
    propsName: "status",
    displayName: "name",
    filterField: "",
  },
  {
    id: "date",
    name: "Année",
    propsName: "date",
    displayName: "name",
    filterField: "",
  },
  {
    id: "startDate",
    name: "Date de début",
    propsName: "date",
    displayName: "name",
    filterField: "",
    isDate: true,
  },
  {
    id: "endDate",
    name: "Date de fin",
    propsName: "date",
    displayName: "name",
    filterField: "",
    isDate: true,
  },
];

export const filterValidationCriteria = [
  {
    id: "responsable",
    name: "Ajouté par",
    propsName: "users",
    displayName: "username",
    filterField: "",
  },
  {
    id: "service",
    name: "Service",
    propsName: "services",
    displayName: "name",
    filterField: "",
  },
  {
    id: "document_type",
    name: "Type de document",
    propsName: "documentTypes",
    displayName: "name",
    filterField: "",
  },
  {
    id: "date",
    name: "Année",
    propsName: "date",
    displayName: "name",
    filterField: "",
  },
];

export const STATUS = [
  {
    id: "APPROVED",
    name: "Validé",
  },
  {
    id: "DECLINED",
    name: "Rejeté",
  },
  {
    id: "WAITING_VALIDATION",
    name: "En cours de validation",
  },
];

export const YEARS = [
  {
    id: "2021",
    name: "2021",
  },
  {
    id: "2022",
    name: "2022",
  },
  {
    id: "2023",
    name: "2023",
  },
  {
    id: "2024",
    name: "2024",
  },
  {
    id: "2025",
    name: "2025",
  },
  {
    id: "2026",
    name: "2026",
  },
];

export const defaultFilterCriteria = {};

export default [filterCriteria, STATUS, YEARS, defaultFilterCriteria];
