import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button/Button";
import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
import DropZoneComp from "../DropDownFile/FileComponent";
import PRIMITIVE_TYPES from "../AddFieldType/constant";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import img from "../../images/picture.png";
import Tooltip from "@material-ui/core/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;
const styles = {
  textField: {
    // width: 392,
    borderRadius: "8px !important",
  },
  approveBtn: {
    float: "right",
    color: "white",
    backgroundColor: "#10277C",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C5DCFA87",
      boxShadow: "none",
    },
  },
  typography: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "138%",
    letterSpacing: "0.0075em",
    textAlign: "left",
  },
  fileContainer: {
    display: "flex",
    width: "100%",
    height: "184px",
    border: "1px dashed rgba(42, 105, 188, 0.37)",
    borderRadius: 8,
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  displayFile: {
    display: "flex",
    width: "100%",
    height: 65,
    borderRadius: 8,
    margin: "8px 15px",
    padding: "0px 8px",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#C5DCFA66 40%",
  },
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
class AddNewDocumentType extends Component {
  state = {
    documentType: {
      name: "",
      departmentId: "",
      additionalFields: [],
    },
    field: {
      type: {
        id: "",
      },
      name: "",
      unique: false,
    },
    newType: {
      name: "",
      type: "",
      file: null,
    },
  };

  onSaveAction = () => {
    this.props.requestAddDocumentType({
      ...this.state.documentType,
      additionalFields: this.state.documentType.additionalFields.map(
        (a, i) => ({ ...a, order: i })
      ),
    });
    this.initDocumentType();
    this.initField();
  };

  removeField = (index) => {
    let documentType = this.state.documentType;
    documentType.additionalFields.splice(index, 1);
    this.setState({ documentType: documentType });
  };

  onAddFieldAction = () => {
    if (this.state.field.type === "Nouveau Type") return;
    let documentType = this.state.documentType;
    documentType.additionalFields.push(this.state.field);
    this.setState({ documentType: documentType });
    this.initField();
    this.initFieldType();
  };

  initField = () => {
    const field = {
      type: {
        id: "",
      },
      name: "",
      unique: false,
    };
    this.setState({ field: field });
  };

  initFieldType = () => {
    this.setState({
      newType: {
        name: "",
        type: "",
        file: null,
        unique: false,
      },
    });
  };

  initDocumentType = (selectedDocumentType) => {
    let documentType = {
      name: "",
      departmentId: "",
      additionalFields: [],
    };
    if (selectedDocumentType) {
      documentType = {
        id: selectedDocumentType.id,
        name: selectedDocumentType.name,
        departmentId: selectedDocumentType.department.id,
        additionalFields: selectedDocumentType.additionalFields,
      };
    }

    this.setState({ documentType: documentType });
    return documentType;
  };

  handleFileDisplay = (file) => {
    this.setState({ newType: { ...this.state.newType, file: file[0] } });
  };

  onDeleteFile = () => {
    this.setState({ newType: { ...this.state.newType, file: null } });
  };

  onAddFieldType = () => {
    if (
      this.state.newType.name !== "" &&
      this.state.newType.type !== "" &&
      this.state.newType.file
    ) {
      this.props.addFieldType(this.state.newType);
    }
  };

  renderAddFieldType = () => {
    const type = this.state.newType;
    const { classes } = this.props;
    return (
      <>
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={8}
        >
          <FormControl style={{ margin: 16, width: "100%" }}>
            <TextField
              size="small"
              required
              variant="outlined"
              value={type.name}
              type="text"
              label="Nom de type"
              onChange={(event) =>
                this.setState({
                  newType: { ...type, name: event.target.value },
                })
              }
              name="name"
            />
          </FormControl>
          <FormControl
            style={{ margin: 16, width: "100%" }}
            variant="outlined"
            required
          >
            <InputLabel style={{ top: -8 }}>Type du nouveau type</InputLabel>
            <Select
              margin="dense"
              onChange={(event) =>
                this.setState({
                  newType: { ...type, type: event.target.value },
                })
              }
              label="Type du nouveau type "
              style={{
                alignItems: "center",
                width: "100%",
              }}
              value={type.type}
            >
              {PRIMITIVE_TYPES &&
                PRIMITIVE_TYPES.map((elt, index) => (
                  <MenuItem key={index} value={elt}>
                    <p className={classes.typography}>{elt}</p>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={8}>
          <Typography
            align="left"
            style={{ color: "#848484", margin: "16px 0px 0px 16px" }}
          >
            Chargement du type
          </Typography>
        </Grid>
        <Grid item className={classes.fileContainer} lg={8}>
          <DropZoneComp
            onFileDrop={this.handleFileDisplay}
            acceptedTypes={[".csv"]}
          />
        </Grid>
        {type.file && (
          <Grid item className={classes.displayFile} lg={8}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={img} alt="doc" style={{ height: 35, marginRight: 8 }} />
              <Typography>{type.file.name}</Typography>
            </div>
            <div>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => this.onDeleteFile()}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        )}
        <Grid item lg={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              margin: "0px 20px",
            }}
          >
            <Button
              id="save-doc-button"
              variant="contained"
              className={classes.approveBtn}
              onClick={this.onAddFieldType}
              style={{ maxWidth: "300px" }}
            >
              <p className={classes.typography}>Enregistrer</p>
            </Button>
          </div>
        </Grid>
      </>
    );
  };

  componentWillMount() {
    if (this.props.documentType) {
      const documentType = this.initDocumentType(this.props.documentType);
      this.setState({ documentType: documentType });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.documentType &&
      prevProps.documentType !== this.props.documentType
    ) {
      const documentType = this.initDocumentType(this.props.documentType);
      this.setState({ documentType: documentType });
    }
    if (
      this.props.fieldTypes &&
      prevProps.fieldTypes !== this.props.fieldTypes
    ) {
      this.initFieldType();
      this.initField();
    }
  }

  getListStyle = (isDraggingOver) => ({
    background: "#FFFFFF",
    padding: grid,
    width: 800,
  });
  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#FFFFFF",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const additionalFields = reorder(
      this.state.documentType.additionalFields,
      result.source.index,
      result.destination.index
    );

    this.setState({
      ...this.state,
      documentType: {
        ...this.state.documentType,
        additionalFields: additionalFields,
      },
    });
  };
  render() {
    const { documentType, field } = this.state;
    const { classes, services, fieldTypes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Grid item lg={12}>
          <Typography align="left" style={{ color: "#848484", marginLeft: 16 }}>
            Entête
          </Typography>
        </Grid>
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={4}
        >
          <FormControl required style={{ margin: 16, width: "100%" }}>
            <TextField
              size="small"
              required
              variant="outlined"
              value={documentType.name}
              type="text"
              label="Nom de type"
              onChange={(event) =>
                this.setState({
                  documentType: { ...documentType, name: event.target.value },
                })
              }
              name="name"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={4}
        >
          <FormControl
            style={{ margin: 16, width: "100%" }}
            variant="outlined"
            required
          >
            <InputLabel style={{ top: -8 }}>Services</InputLabel>
            <Select
              margin="dense"
              name="departmentId"
              key="departmentId"
              value={documentType.departmentId}
              onChange={(event) =>
                this.setState({
                  documentType: {
                    ...documentType,
                    departmentId: event.target.value,
                  },
                })
              }
              label="Service"
              style={{
                alignItems: "center",
                width: "100%",
              }}
            >
              {services &&
                services.map((elt) => (
                  <MenuItem key={elt.id} value={elt.id}>
                    {elt.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12}>
          <Typography
            align="left"
            style={{ color: "#848484", marginLeft: 16, marginTop: 48 }}
          >
            Champs Additionnel
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 20 }} xs={12}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.documentType.additionalFields.map(
                    (item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={"do" + (item.id ? item.id : item.name)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Box style={{ width: 300 }}>{item.name}</Box>
                            <Box style={{ width: 300 }}>
                              {fieldTypes.filter(
                                (type) => type.id === item.type.id
                              ).length > 0
                                ? fieldTypes.filter(
                                    (type) => type.id === item.type.id
                                  )[0].name
                                : ""}
                            </Box>
                            <Box
                              style={{
                                width: 200,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  this.removeField(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={4}
        >
          <FormControl required style={{ margin: 16, width: "100%" }}>
            <TextField
              size="small"
              variant="outlined"
              value={field.name}
              type="text"
              label="Nom de champs"
              onChange={(event) =>
                this.setState({ field: { ...field, name: event.target.value } })
              }
              name="name"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
          lg={4}
        >
          <FormControl
            style={{ margin: 16, width: "100%" }}
            variant="outlined"
            required
          >
            <InputLabel style={{ top: -8 }}>Type de champ</InputLabel>
            <Select
              margin="dense"
              onChange={(event) =>
                this.setState({ field: { ...field, type: event.target.value } })
              }
              label="Type de champ"
              style={{
                alignItems: "center",
                width: "100%",
              }}
              value={field.type}
            >
              {fieldTypes &&
                fieldTypes.map((elt) => (
                  <MenuItem key={elt.id} value={elt}>
                    {elt.name}
                  </MenuItem>
                ))}
              <MenuItem key={0} value="Nouveau Type" style={{ color: "blue" }}>
                Nouveau Type
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2} style={{ display: "flex", width: "100%", justify: "center", alignItems:'center' }}>
          <FormControlLabel
              style={{marginTop:16}}
              value="unique"
              control={<Switch
                checked={field.unique}
                color="primary"
                onChange={(event) =>
                  this.setState({ field: { ...field, unique: event.target.checked } })
                } />}
              label="Unique ?"
              labelPlacement="start"
            />
        </Grid>
        {this.state.field &&
          this.state.field.type === "Nouveau Type" &&
          this.renderAddFieldType()}
        {this.state.field && this.state.field.type !== "Nouveau Type" && (
          <Grid
            item
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            lg={2}
          >
            <Button
              style={{ margin: 16, width: "100%" }}
              id="save-doc-button"
              variant="contained"
              className={classes.approveBtn}
              onClick={this.onAddFieldAction}
            >
              Ajouter le champ
            </Button>
          </Grid>
        )}
        <Grid
          container
          lg={12}
          style={{
            marginTop: 48,
            marginEnd: 16,
            display: "flex-end",
            width: "80%",
            justifyContent: "flex-end",
          }}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Button
              id="save-doc-button"
              variant="contained"
              className={classes.approveBtn}
              onClick={this.onSaveAction}
            >
              Créer le type de document
            </Button>
          </Grid>
          <Grid item xs={2} style={{ backgroundColor: "yellow" }} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddNewDocumentType);
